import { doesUserExist } from "api/user";
import { useEffect } from "react";
import { useAuth } from "./useAuth";
import { useFetch } from "./useFetch";
import useRequest from "./useRequest";

const useUser = () => {
    const { loggedIn } = useAuth();
    const doesUserExistRequest = useRequest(({ token }) => {
        doesUserExist({ token, country: "", specialization: "" });
    });

    useFetch(() => {
        if (loggedIn) doesUserExistRequest.sendRequest();
    }, [doesUserExist]);
};

export { useUser };
