import axios from 'axios';
import store from 'redux/store';

const PORT = 5001;
axios.defaults.baseURL =
  process.env.NODE_ENV !== 'production'
    ? `https://localhost:${PORT}/api`
    : process.env.REACT_APP_API_URL;

const get = async (endpoint: string, token?: string) => {
  return axios.get(endpoint, { headers: { Authorization: `Bearer ${token}` } });
};

const post = async (endpoint: string, body: any, token?: string) => {
  return axios.post(endpoint, body, { headers: { Authorization: `Bearer ${token}` } });
};

const del = async (endpoint: string, token?: string) => {
  return axios.delete(endpoint, { headers: { Authorization: `Bearer ${token}` } });
};

const endpointBuilder = (endpoint: string, params?: { [key: string]: any }) => {
  var result = `${endpoint}`;

  const specialization = store.getState().user.specialization;
  //   const specialization = undefined;
  const region = 'pl';
  result += `?region=${region}`;
  if (specialization !== undefined) {
    result += `&filters=${specialization}`;
  }

  if (params !== undefined) {
    const keys = Object.keys(params);
    for (var i = 0; i < keys.length; i++) {
      if (params[keys[i]] !== undefined)
        if (Array.isArray(params[keys[i]])) {
          result += `&${keys[i]}=`;
          result += (params[keys[i]] as string[]).reduce((curr, prev) => {
            return curr + `&${keys[i]}=` + prev;
          });
        } else {
          result += `&${keys[i]}=${params[keys[i]]}`;
        }
    }
  }
  return result;
};

export { get, endpointBuilder, post, del };
