import { EpisodeModalCard } from "components/EpisodeCard";
import { SliderItemPosition } from "components/Sliders/Slider";
import { useListener } from "hooks/useListener";
import { useModalManager } from "hooks/useModal";
import { useModalData } from "hooks/useModalData";
import Multimedia from "interfaces/data/Multimedia.interface";
import { FC, useEffect, useRef, useState } from "react";
import { Modal } from "./Modal";

interface EpisodeModalProps {}
interface EpidodeModalAdditionalData {
    data: Multimedia;
    itemPosition: SliderItemPosition;
}

export const EPISODE_MODAL_NAME = "episode";
const EpisodeModal: FC<EpisodeModalProps> = () => {
    const data = useModalData<EpidodeModalAdditionalData>(EPISODE_MODAL_NAME);
    const { closeModal } = useModalManager();
    const contentRef = useRef<HTMLDivElement | null>(null);
    const [targetSizes, setTargetSizes] = useState({ width: 0, height: 0 });

    useEffect(() => {
        if (contentRef?.current) {
            setTargetSizes({
                width: contentRef.current.clientWidth,
                height: contentRef.current.clientHeight,
            });
        }
    }, [data?.initHeight]);

    return data ? (
        <Modal
            itemProps={data}
            renderItem={(itemProps) => (
                <div
                    ref={contentRef}
                    style={{
                        width: !data.mobile ? "fit-content" : "100vw",
                        height: !data.mobile ? "fit-content" : "100vh",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                    onMouseLeave={!data.mobile ? () => closeModal(EPISODE_MODAL_NAME) : undefined}
                >
                    <EpisodeModalCard data={itemProps?.additionalData?.data} mobile={data.mobile} />
                </div>
            )}
            targetWidth={targetSizes.width}
            targetHeight={targetSizes.height}
            initWidth={data.initWidth}
            initHeight={data.initHeight}
            right={
                data.mobile
                    ? "initial"
                    : data.additionalData?.itemPosition === SliderItemPosition.RIGHT
                    ? window.innerWidth -
                      data.x -
                      (window.innerWidth - document.body.clientWidth) -
                      (data.initWidth || 0)
                    : "initial"
            }
            left={
                data.mobile
                    ? 0
                    : data.additionalData?.itemPosition === SliderItemPosition.LEFT
                    ? data.x
                    : data.additionalData?.itemPosition === SliderItemPosition.INNER
                    ? data.x + data.initWidth / 2
                    : "initial"
            }
            transform={
                data.mobile
                    ? "initial"
                    : data.additionalData?.itemPosition === SliderItemPosition.INNER
                    ? `translate(-50%, -50%)`
                    : `translate(0, -50%)`
            }
            top={data.mobile ? 0 : data.y + data.initHeight / 2}
            open={data.open}
            position={data.mobile ? "fixed" : "absolute"}
        />
    ) : null;
};

export { EpisodeModal };
