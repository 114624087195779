import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface ModalData<T extends unknown> {
  x: number;
  y: number;
  initWidth: number;
  initHeight: number;
  open: boolean;
  additionalData?: T;
  mobile: boolean;
}

interface ModalSlice {
  modals: {
    [key: string]: ModalData<any>;
  };
}

const INITIAL_STATE: ModalSlice = {
  modals: {},
};

export interface ModalDataPayload<T extends unknown> {
  x?: number;
  y?: number;
  initWidth?: number;
  initHeight?: number;
  open?: boolean;
  additionalData?: T;
  mobile?: boolean;
}

const { actions, reducer } = createSlice({
  name: 'modal',
  initialState: INITIAL_STATE,
  reducers: {
    updateModalData(state, action: { payload: { key: string; data: ModalDataPayload<any> } }) {
      const { key, data } = action.payload;
      state.modals[key] = { ...state.modals[key], ...data };
    },
    setModalDisplayState(state, action: { payload: { key: string; display: boolean } }) {
      const { key, display } = action.payload;
      state.modals[key].open = display;
    },
    closeAllModals(state) {
      const modals = state.modals;
      const modalsKeys = Object.keys(modals);
      for (const modalKey of modalsKeys) {
        modals[modalKey].open = false;
      }
    },
  },
  extraReducers: {},
});

const selectModalDataByKey = (key: string, state: RootState) => state.modal.modals[key];
export const { updateModalData, setModalDisplayState, closeAllModals } = actions;
export { selectModalDataByKey };
export default reducer;
