import { FC } from 'react';

interface DropDownProps {
  children: React.ReactNode;
  isOpen: boolean;
  wrapperClassName: string;
}

const DropDown: FC<DropDownProps> = ({ children, isOpen, wrapperClassName }) => {
  return isOpen ? (
    <div
      className={wrapperClassName}
      onClick={e => e.stopPropagation()}
    >
      {children}
    </div>
  ) : null;
};

export { DropDown };
