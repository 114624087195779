import { FC } from 'react';
import styles from 'styles/Page.module.css';
import { Footer } from './Footer';
import { Header } from './Header';

interface PageProps {
  children?: React.ReactNode;
}

const Page: FC<PageProps> = ({ children }) => {
  return (
    <div className={styles.Wrapper}>
      <Header />
      <div className={styles.Content}>{children}</div>
      <Footer />
    </div>
  );
};

export { Page };
