import { FC, useLayoutEffect, useRef, useState } from "react";
import { Image } from "./Image";
import styles from "styles/LoadingScreen.module.css";
import { usePreloadState } from "hooks/usePreload";
import loader from "assets/loader.gif";

const LoadingScreen: FC = () => {
    const [showLoadingScreen, setShowLoadingScreen] = useState(true);
    const preloadState = usePreloadState();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        window.onbeforeunload = () => {
            window.scrollTo(0, 0);
        };
    }, []);

    useLayoutEffect(() => {
        var timeout: NodeJS.Timeout, nextTimeout: NodeJS.Timeout;
        const root = document.getElementById("root");
        root!!.style.overflowY = "hidden";
        root!!.style.height = "100vh";
        const everyTrue = Object.values(preloadState).every((e) => e === true);
        if (everyTrue) {
            containerRef.current!!.style.opacity = "0";
            contentRef.current!!.style.scale = "0.8";
            timeout = setTimeout(() => {
                setShowLoadingScreen(false);
                // nextTimeout = setTimeout(() => {
                root!!.style.height = "initial";
                root!!.style.overflowY = "initial";
                // }, 50);
            }, 2000);
        } else {
            setShowLoadingScreen(true);
        }
        return () => {
            clearTimeout(timeout);
            clearTimeout(nextTimeout);
        };
    }, [JSON.stringify(preloadState)]);

    return showLoadingScreen ? (
        <div ref={containerRef} className={styles.Wrapper}>
            <div ref={contentRef} className={styles.Loader}>
                <Image src={loader} alt={"Loader"} className={styles.LoaderGif} fetchFromLocalServer />
            </div>
        </div>
    ) : null;
};

export default LoadingScreen;
