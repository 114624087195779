import Category from 'interfaces/data/Category.interface';

const flattenCategories = (categories: Category[]) => {
  const recurs = (result: Category[], category: Category) => {
    if (category.children.length > 0)
      for (const child of category.children) {
        recurs(result, child);
      }
    result.push(category);
  };
  const result: Category[] = [];
  categories.forEach(item => recurs(result, item));
  return result;
};

export { flattenCategories };
