import { getGroup, getGroupArticle, getGroups } from "api/group";
import {
    CircleFavoriteButton,
    CircleShareButton,
    FavoriteButton,
    FavoriteButtonType,
    OpenButton,
    PlayButton,
    ShareButton,
} from "components/Button";
import { ContentWrapper } from "components/ContentWrapper";
import { Description } from "components/Description";
import { MaterialArticle } from "components/MaterialArticle";
import { MaterialDescription } from "components/MaterialDescription";
import { PageContent } from "components/PageContent";
import { EpisodeSlider } from "components/Sliders/EpisodeSlider";
import { TitledSection } from "components/TitledSection";
import { Video } from "components/Video";
import { VideoPlayer } from "components/VideoPlayer";
import { VideoPlayerWithControlls } from "components/VideoPlayerWithControlls";
import { withScrollUp } from "hocs/withScrollUp";
import { useFetch } from "hooks/useFetch";
import { useLocation } from "hooks/useLocation";
import useRequest from "hooks/useRequest";
import Article from "interfaces/data/Article.interface";
import Group from "interfaces/data/Group.interface";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Material: FC = () => {
    const { setLocation } = useLocation();
    const { material, episode } = useParams();
    const materialData = useRequest<Group>(({ token }) => getGroup({ groupName: material || "", token }));
    // const articleData = useRequest<Article>(({ token }) => getGroupArticle({ groupName: material || "", token }));

    useFetch(() => {
        if (material !== undefined) {
            materialData.sendRequest();
            // articleData.sendRequest();
        }
    }, [material]);

    useEffect(() => {
        setLocation({
            name: materialData.response.data?.title || "",
            prevLocation: undefined,
            navLinks: [],
            favoriteButton:
                episode === undefined
                    ? {
                          type: FavoriteButtonType.Material,
                          url: materialData.response.data?.nameInUrl || "",
                      }
                    : undefined,
            shareButton:
                episode === undefined
                    ? {
                          pathname: `/${materialData.response.data?.nameInUrl || ""}`,
                      }
                    : undefined,
        });
    }, [materialData.response.data, episode]);

    const selectedMaterialIndex = materialData.response.data?.multimedia.findIndex((e) => e.nameInUrl === episode) || 0;

    return (
        <PageContent marginTop="10em">
            {/* <TitledSection title={materialData.response.data?.title || ""} gap={"1.5em"} titleColor={"var(--color-4)"}> */}
            <ContentWrapper>
                {episode != undefined ? (
                    <Video
                        video={materialData.response.data?.multimedia[selectedMaterialIndex] || null}
                        prevVideoNameInUrl={
                            materialData.response.data?.multimedia[selectedMaterialIndex - 1]?.nameInUrl || undefined
                        }
                        nextVideoNameInUrl={
                            materialData.response.data?.multimedia[selectedMaterialIndex + 1]?.nameInUrl || undefined
                        }
                        loading={materialData.response.loading}
                        error={materialData.response.error}
                    />
                ) : (
                    <MaterialDescription
                        data={materialData.response.data}
                        loading={materialData.response.loading}
                        error={materialData.response.error}
                    />
                )}
            </ContentWrapper>
            {/* </TitledSection> */}
            {(!materialData.response.loading &&
                materialData.response.data &&
                materialData.response.data.multimedia.length > 1) ||
            materialData.response.loading ||
            materialData.response.error != null ? (
                <TitledSection title={"Episodes"} titleSize={"1.5em"} titleColor={"var(--color-7)"} gap={".5em"}>
                    <EpisodeSlider
                        elements={materialData.response.data?.multimedia || null}
                        error={materialData.response.error}
                        loading={materialData.response.loading}
                        firstElementIndex={selectedMaterialIndex < 0 ? 0 : selectedMaterialIndex}
                        activeMaterialNameInUrl={
                            materialData.response.data?.multimedia?.[selectedMaterialIndex]?.nameInUrl
                        }
                        numberOfElementsToSlide={1}
                        displayPaginationIndicator={false}
                    />
                </TitledSection>
            ) : episode === undefined ? (
                <ContentWrapper>
                    <PlayButton
                        url={`/${materialData.response.data?.nameInUrl}/${materialData.response.data?.multimedia[0].nameInUrl}`}
                    />
                </ContentWrapper>
            ) : null}
            <MaterialArticle
                data={materialData.response.data?.articleDetails || null}
                loading={materialData.response.loading}
            />
        </PageContent>
    );
};

export default withScrollUp(Material);
