import { EPISODE_MODAL_NAME } from "components/Modals/EpisodeModal";
import { MATERIAL_MODAL_NAME } from "components/Modals/MaterialModal";
import { SEARCH_MODAL_NAME } from "components/Modals/SearchModal";
import { SHARE_MODAL_NAME } from "components/Modals/ShareModal";
import { useLayoutEffect } from "react";
import { useModalManager } from "./useModal";

const useModalsInitialization = () => {
    const { initModal } = useModalManager();

    useLayoutEffect(() => {
        initModal(EPISODE_MODAL_NAME);
        initModal(SEARCH_MODAL_NAME);
        initModal(SHARE_MODAL_NAME);
        initModal(MATERIAL_MODAL_NAME);
    }, []);
};

export { useModalsInitialization };
