import React from 'react';
import ReactDOM from 'react-dom/client';

import 'assets/fonts/VoltaModernDisplay-15XXThin.otf';
import 'assets/fonts/VoltaModernDisplay-16XXThinItalic.otf';
import 'assets/fonts/VoltaModernDisplay-25XThin.otf';
import 'assets/fonts/VoltaModernDisplay-26XThinItalic.otf';
import 'assets/fonts/VoltaModernDisplay-35Thin.otf';
import 'assets/fonts/VoltaModernDisplay-36ThinItalic.otf';
import 'assets/fonts/VoltaModernDisplay-45Light.otf';
import 'assets/fonts/VoltaModernDisplay-46LightItalic.otf';
import 'assets/fonts/VoltaModernDisplay-55Roman.otf';
import 'assets/fonts/VoltaModernDisplay-56Italic.otf';
import 'assets/fonts/VoltaModernDisplay-65Medium.otf';
import 'assets/fonts/VoltaModernDisplay-66MediumItalic.otf';
import 'assets/fonts/VoltaModernDisplay-75Bold.otf';
import 'assets/fonts/VoltaModernDisplay-76BoldItalic.otf';
import 'assets/fonts/VoltaModernDisplay-95Black.otf';
import 'assets/fonts/VoltaModernDisplay-96BlackItalic.otf';

import 'styles/global/fonts.css';
import 'styles/global/colors.css';

import 'styles/global/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from 'redux/store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
