import { useLocation } from "hooks/useLocation";
import { FC } from "react";
import { BsShare } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import styles from "styles/LocationBar.module.css";
import {
    Button,
    CircleButton,
    CircleFavoriteButton,
    CircleShareButton,
    FavoriteButton,
    FavoriteButtonType,
    ShareButton,
} from "./Button";
import { ContentWrapper } from "./ContentWrapper";

interface LocationBarProps {
    sticky: boolean;
    top: number;
}

const LocationBar: FC<LocationBarProps> = ({ sticky, top }) => {
    const { current } = useLocation();

    const navigate = useNavigate();

    return (
        <div
            style={{
                position: sticky ? "fixed" : "absolute",
                left: 0,
                top: sticky ? 0 : top,
                backgroundColor: sticky ? "var(--color-17)" : "transparent",
                width: "100%",
                boxShadow: sticky ? "0px 0px .5rem 0px #000000" : "none",
            }}
            className={styles.Wrapper}
        >
            <ContentWrapper>
                <div className={styles.Content}>
                    <div className={styles.CurrentLocation}>{current?.name}</div>
                    {current?.prevLocation ? (
                        <div className={styles.PreviousLocation}>
                            <Button
                                className={styles.DarkButton}
                                onClick={() => navigate(`${current?.prevLocation?.url}`)}
                            >
                                {current?.prevLocation?.name}
                            </Button>
                        </div>
                    ) : null}
                    <div className={styles.SubLocations}>
                        {current?.navLinks.map((item) => (
                            <Button
                                className={styles.DarkButton}
                                key={item.url}
                                onClick={() => navigate(`${item.url}`)}
                            >
                                {item.name}
                            </Button>
                        ))}
                        {current?.favoriteButton ? (
                            <CircleFavoriteButton
                                nameInUrl={current.favoriteButton.url}
                                type={current.favoriteButton.type || FavoriteButtonType.Material}
                            />
                        ) : null}
                        {current?.shareButton ? (
                            <CircleShareButton pathname={current.shareButton.pathname || "/"} />
                        ) : null}
                    </div>
                </div>
            </ContentWrapper>
        </div>
    );
};

export { LocationBar };
