import { useToast } from "hooks/useToast";
import { FC, useEffect, useRef, useState } from "react";
import { BsX } from "react-icons/bs";
import { ToastType } from "redux/slices/toastSlice";
import styles from "styles/Toast.module.css";

interface ToastProps {
    uid: string;
    message: string;
    duration: number | null;
    type: ToastType;
}

const Toast: FC<ToastProps> = ({ uid, message, duration, type }) => {
    const { removeToast } = useToast();
    const [isOpen, setIsOpen] = useState(true);
    const displayCloseButton = duration === null;

    const closeToast = () => {
        setIsOpen(false);
        setTimeout(() => {
            removeToast(uid);
        }, 500);
    };

    useEffect(() => {
        var openTimeout: NodeJS.Timeout, lifeTimeout: NodeJS.Timeout;
        if (duration !== null) {
            openTimeout = setTimeout(() => {
                lifeTimeout = setTimeout(() => {
                    closeToast();
                }, duration);
            }, 500);
        }
        return () => {
            clearTimeout(openTimeout);
            clearTimeout(lifeTimeout);
        };
    }, []);

    return (
        <div className={`${styles.Wrapper} ${isOpen ? styles.ToastOpen : styles.ToastClose}`} data-toast-type={type}>
            <div className={styles.ToastContent}>
                <div>
                    {type === ToastType.ERROR ? (
                        <div style={{ fontSize: "1.1em", paddingBottom: ".2em" }}>
                            <strong>Error</strong>
                            <br />
                        </div>
                    ) : null}
                    {message}
                </div>
            </div>
            {displayCloseButton ? (
                <div onClick={closeToast}>
                    <div className={styles.ToastCloseButton}>
                        <BsX className={styles.ToastXIcon} />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export { Toast };
