import axios from "axios";

const getImage = async (imageSrc: string) => {
    return axios.get(`https://images.mediplayer.com/mediplayer-files/${imageSrc}`, {
        headers: { MediplayerApiKey: "MediplayerFileAccess" },
        responseType: "blob",
    });
};

export { getImage };
