import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface SliderElementCurrentSettings {
  elemWidth: number;
  elemHeight: number;
  gap: number;
  padding: number;
  mobile: boolean;
  numberOfElementsToDisplay: number;
  numberOfElementsToSlide: number;
}

export interface SliderElementInitialSettings {
  displayVariations: {
    maximumWindowWidth?: number;
    numberOfElementsToDisplay: number;
    numberOfElementsToSlide: number;
    gap: number;
    padding: number;
    mobile?: boolean;
    heightToWidthRatio?: number;
  }[];
  defaultheightToWidthRatio: number;
}

interface SliderSlice {
  currentSettings: {
    [key: string]: SliderElementCurrentSettings | undefined;
  };
  initialSettings: {
    [key: string]: SliderElementInitialSettings | undefined;
  };
}

const INITIAL_STATE: SliderSlice = {
  currentSettings: {},
  initialSettings: {},
};

const { actions, reducer } = createSlice({
  name: 'slider',
  initialState: INITIAL_STATE,
  reducers: {
    updateCurrentSettings(
      state,
      action: { payload: { key: string; settings: SliderElementCurrentSettings } }
    ) {
      const { key, settings } = action.payload;
      state.currentSettings[key] = settings;
    },
    updateInitialSettings(
      state,
      action: {
        payload: { key: string; settings: SliderElementInitialSettings };
      }
    ) {
      const { key, settings } = action.payload;
      state.initialSettings[key] = settings;
    },
  },
  extraReducers: {},
});

const selectSliderCurrentSettingsForElement = (key: string, state: RootState) =>
  state.slider.currentSettings[key];
const selectAllCurrentSettings = (state: RootState) => state.slider.currentSettings;
const selectAllInitialSettings = (state: RootState) => state.slider.initialSettings;

export const { updateCurrentSettings, updateInitialSettings } = actions;
export {
  selectSliderCurrentSettingsForElement,
  selectAllCurrentSettings,
  selectAllInitialSettings,
};
export default reducer;
