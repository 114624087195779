import { FC } from 'react';
import styles from 'styles/LoadingAnimation.module.css';

export interface LoadingAnimationProps {
  animationWidth?: string;
  animationHeight?: string;
  animationYTranslation?: string;
  animationDuration?: string;
}

const LoadingAnimation: FC<LoadingAnimationProps> = ({
  animationHeight,
  animationWidth,
  animationYTranslation,
  animationDuration,
}) => {
  return (
    <div
      style={
        {
          '--animation-duration': animationDuration || '2s',
          '--animation-width': animationWidth || '200%',
          '--animation-height': animationHeight || '200%',
          '--animation-y-translation': animationYTranslation || '-70%',
        } as React.CSSProperties
      }
      className={styles.Wrapper}
    ></div>
  );
};

export default LoadingAnimation;
