import { FC } from "react";
import styles from "styles/Footer.module.css";
import { ContentWrapper } from "components/ContentWrapper";
import { Image } from "components/Image";
import mediplayerLogo from "assets/images/mediplayerLogo.png";
import sandozLogo from "assets/images/sandozLogo.png";
import { Link } from "react-router-dom";
import { NewsletterForm } from "./Forms/NewsletterForm";

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
    return (
        <footer className={styles.Wrapper}>
            <ContentWrapper>
                <div className={styles.Content}>
                    <div className={styles.LogoWrapper}>
                        <Image
                            src={mediplayerLogo}
                            alt={"mediplayerLogo"}
                            className={styles.Logo}
                            fetchFromLocalServer
                        />
                    </div>
                    <div className={styles.AdditionalLinks}>
                        {/* <Link
              to={''}
              className={'text-decoration-disabled'}
            >
              <span>Cookie settigns</span>
            </Link>
            <Link
              to={''}
              className={'text-decoration-disabled'}
            >
              <span>Help center</span>
            </Link>
            <Link
              to={''}
              className={'text-decoration-disabled'}
            >
              <span>Contact us</span>
            </Link> */}
                        <Link to={""} className={"text-decoration-disabled"}>
                            <span>Privacy policy</span>
                        </Link>
                        <Link to={""} className={"text-decoration-disabled"}>
                            <span>Terms of use</span>
                        </Link>
                    </div>
                    {/* <div className={styles.Newsletter}>
            <h3>Subscribe to our newsletter!</h3>
            <NewsletterForm />
          </div> */}
                    <div className={styles.Copyrights}>
                        <div className={styles.LogoWrapper}>
                            <Image src={sandozLogo} alt={"sandozLogo"} className={styles.Logo} fetchFromLocalServer />
                        </div>
                        MediPlayer©2022
                    </div>
                </div>
            </ContentWrapper>
        </footer>
    );
};

export { Footer };
