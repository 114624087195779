import { FC, useCallback, useEffect, useState } from 'react';
import { Link, To, useLocation } from 'react-router-dom';
import styles from 'styles/NavItem.module.css';
import { DropDown } from './DropDown';

interface NavItemProps {
  displayName: React.ReactNode;
  href?: To;
  children?: React.ReactNode;
  disableLocationChange?: boolean;
  onClick?: Function;
}

const NavItem: FC<NavItemProps> = ({
  displayName,
  disableLocationChange = false,
  href,
  children,
  onClick,
}) => {
  const { pathname } = useLocation();
  const [isSubNavOpen, setSubNavOpen] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);
  const handleMouseOver = useCallback(() => setMouseOver(true), []);
  const handleMouseLeave = useCallback(() => setMouseOver(false), []);

  useEffect(() => {
    var timeout: NodeJS.Timeout;
    if (!mouseOver && isSubNavOpen) {
      timeout = setTimeout(() => setSubNavOpen(false), 500);
    } else if (mouseOver && !isSubNavOpen) {
      setSubNavOpen(true);
    }
    return () => clearTimeout(timeout);
  }, [mouseOver]);

  const ItemContent = (
    <div
      onMouseOver={children ? handleMouseOver : undefined}
      onMouseLeave={children ? handleMouseLeave : undefined}
      onClick={() => onClick?.()}
      className={
        (href && pathname.includes(href.toString()) && href !== '/') || href === pathname
          ? styles.WrapperActive
          : styles.Wrapper
      }
    >
      <span className={styles.ItemName}>{displayName}</span>
      {children ? (
        <DropDown
          isOpen={isSubNavOpen}
          wrapperClassName={styles.SubNavWrapper}
        >
          {children}
        </DropDown>
      ) : null}
    </div>
  );

  return !disableLocationChange ? (
    <Link
      className='text-decoration-disabled'
      to={href || ''}
    >
      {ItemContent}
    </Link>
  ) : (
    ItemContent
  );
};

export { NavItem };
