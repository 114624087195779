import { Share } from 'components/Share';
import { useListener } from 'hooks/useListener';
import { useModalData } from 'hooks/useModalData';
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from './Modal';

interface ShareModalProps {}
interface ShareModalAdditionalData {
  url: string;
}

export const SHARE_MODAL_NAME = 'share';
const ShareModal: FC<ShareModalProps> = () => {
  const data = useModalData<ShareModalAdditionalData>(SHARE_MODAL_NAME);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [targetSizes, setTargetSizes] = useState({ width: 0, height: 0 });

  useEffect(() => {
    document.body.style.overflowY = data?.open ? 'hidden' : 'auto';
    updateTargetSizes();
  }, [data?.open]);

  const updateTargetSizes = useCallback(() => {
    if (contentRef?.current) {
      setTargetSizes({
        width: contentRef.current.clientWidth,
        height: contentRef.current.clientHeight,
      });
    }
  }, [contentRef]);

  useListener(window, 'resize', updateTargetSizes, []);

  return data ? (
    <Modal
      itemProps={data}
      renderItem={itemProps =>
        itemProps?.additionalData ? (
          <div
            style={{
              width: 'fit-content',
              height: 'fit-content',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            ref={contentRef}
          >
            <Share url={itemProps.additionalData.url} />
          </div>
        ) : null
      }
      initWidth={500}
      initHeight={50}
      targetWidth={Math.ceil(targetSizes.width)}
      targetHeight={Math.ceil(targetSizes.height)}
      open={data.open}
      left={'50%'}
      top={'50%'}
      transform={'translate(-50%, -50%)'}
      position='fixed'
    />
  ) : null;
};

export { ShareModal };
