import { EffectCallback, useEffect } from "react";
import { selectActiveSpecialization, selectToken } from "redux/slices/userSlice";
import { useAppSelector } from "./useAppSelector";

const useFetch = (callback: EffectCallback, additionalDepts: React.DependencyList = []) => {
    const token = useAppSelector(selectToken);
    const activeSpecialization = useAppSelector(selectActiveSpecialization);
    useEffect(callback, [activeSpecialization, token, ...additionalDepts]);
};

export { useFetch };
