import { useMemo } from "react";

export interface HCASDK {
    setHcaSdkConfig: Function;
    setLoginCallBack: Function;
    setTokenCallBack: Function;
    setCancelCallBack: Function;
    setErrorCallBack: Function;
    isAccountLogged: Function;
    getProfile: Function;
    getAccount: Function;
    getAccessTokenSilent: Function;
    signOut: Function;
    signUp: Function;
    signIn: Function;
}

const useHcaSdk = () => {
    // const hcaSdk = useMemo<HCASDK | undefined>(
    //   () => (window as any).hcaSdk,
    //   [(window as any).hcaSdk]
    // );

    const hcaSdk: HCASDK | undefined = (window as any).hcaSdk;

    return { hcaSdk: hcaSdk!!, hcaSdkReadyToUse: hcaSdk !== undefined && hcaSdk !== null };
};

export { useHcaSdk };
