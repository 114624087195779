import { useCallback, useEffect } from "react";
import * as LocationSlice from "redux/slices/locationSlice";
import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";

const useLocation = () => {
    const dispatch = useAppDispatch();
    const current = useAppSelector(LocationSlice.selectLocation);

    const setLocation = useCallback((newLocation?: LocationSlice.Location) => {
        dispatch(LocationSlice.setLocation(newLocation));
    }, []);

    return { current, setLocation };
};

export { useLocation };
