import { useEffect } from 'react';

const withScrollUp = <T extends {}>(Component: React.ComponentType<T>) => {
  return (props: T) => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return <Component {...props} />;
  };
};

export { withScrollUp };
