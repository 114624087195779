import { useState } from "react";
import { selectToken } from "redux/slices/userSlice";
import { guid } from "utils/guid";
import { useAppSelector } from "./useAppSelector";
import { useToast } from "./useToast";

function useRequest<DATA_TYPE>(action: (args: { token: string; body: any }) => any) {
    const [loading, setLoading] = useState<boolean | null>(null);
    const [error, setError] = useState<any | null>(null);
    const [data, setData] = useState<DATA_TYPE | null>(null);
    const [code, setCode] = useState<string | null>(null);
    const token = useAppSelector(selectToken);
    const { addToast } = useToast();

    const sendRequest = async (body?: any) => {
        // if (token === undefined) return;
        setError(null);
        setLoading(true);
        setData(null);
        setCode(null);
        try {
            const result = await action({ body, token: token || "" });
            setCode(result.status.toString()); //TODO
            setData(result.data.data);
        } catch (err) {
            if ((err as any)?.code === "ERR_NETWORK") {
                setError({ code: "ERR_NETWORK", message: "Server is not responding", data: null });
            } else {
                const error = (err as { response: { data: { code: number; message: string; data: any } } })?.response
                    ?.data;
                setError({ code: error.code, message: error.message, data: error.data });
            }
            setLoading(false);
            throw err;
        }
        setLoading(false);
    };

    return { response: { loading, error, data, code }, sendRequest };
}

export default useRequest;
