import LoadingScreen from "components/LoadingScreen";
import { EpisodeModal, EPISODE_MODAL_NAME } from "components/Modals/EpisodeModal";
import { MaterialModal, MATERIAL_MODAL_NAME } from "components/Modals/MaterialModal";
import { SearchModal } from "components/Modals/SearchModal";
import { ShareModal, SHARE_MODAL_NAME } from "components/Modals/ShareModal";
import { Page } from "components/Page";
import { useAuth, useAuthConfiguration } from "hooks/useAuth";
import { useCategoriesInitialization } from "hooks/useCategoriesInitialization";
import { useFavoritesInitialization } from "hooks/useFavoritesInitialization";
import { useModalManager } from "hooks/useModal";
import { useSlidersInitialization } from "hooks/useSlidersInitialization";
import { useUser } from "hooks/useUser";
import React, { FC, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import AllZoneEpisodes from "routes/AllZoneEpisodes";
import Favorites from "routes/Favorites";
import Home from "routes/Home";
import Material from "routes/Material";
import New from "routes/New";
import NotFound from "routes/NotFound";
import Search from "routes/Search";
import Zone from "routes/Zone";
import "./App.css";
import { useModalsInitialization } from "hooks/useModalsInitialization";
import { ToastManager } from "components/ToastManager";

function App() {
    useAuthConfiguration();
    const { loggedIn } = useAuth();
    useUser();

    return loggedIn ? (
        <>
            <React.StrictMode>
                <Init />
                <BrowserRouter>
                    <UpdateOnLocationChange />
                    <EpisodeModal />
                    <SearchModal />
                    <MaterialModal />
                    <ShareModal />
                    <LoadingScreen />
                    <ToastManager />
                    <Page>
                        <Routes>
                            <Route path="/" element={<Home />} />

                            <Route path="/category/:category" element={<Zone />} />

                            <Route path="/category/:category/episodes" element={<AllZoneEpisodes />} />

                            <Route path="/new" element={<New />} />

                            <Route path="/favorites" element={<Favorites />} />

                            <Route path="/search" element={<Search />} />

                            <Route path="/:material/:episode" element={<Material />} />
                            <Route path="/:material" element={<Material />} />

                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Page>
                </BrowserRouter>
            </React.StrictMode>
        </>
    ) : (
        <>
            <div id="hca_signin"></div>
            <div id="hca_signup"></div>
        </>
    );
}

const UpdateOnLocationChange: FC = () => {
    const { updateModalData } = useModalManager();
    const location = useLocation();

    useEffect(() => {
        updateModalData(EPISODE_MODAL_NAME, { open: false, x: -1000, y: -1000 });
        updateModalData(SHARE_MODAL_NAME, { open: false, x: -1000, y: -1000 });
        updateModalData(MATERIAL_MODAL_NAME, { open: false, x: -1000, y: -1000 });
    }, [location.pathname]);

    return null;
};

const Init: FC = () => {
    useCategoriesInitialization();
    useFavoritesInitialization();
    useSlidersInitialization();
    useModalsInitialization();
    return null;
};

export default App;
