import { FC } from "react";
import { Image } from "./Image";
import styles from "styles/HeroFeaturedMaterial.module.css";
import { ContentWrapper } from "./ContentWrapper";
import Multimedia from "interfaces/data/Multimedia.interface";
import { Description } from "./Description";
import { PlayButton, ShareButton, FavoriteButton, FavoriteButtonType } from "./Button";

interface HeroFeaturedMaterialProps {
    material: Multimedia | null;
    loading: boolean | null;
    error?: any;
}

const HeroFeaturedMaterial: FC<HeroFeaturedMaterialProps> = ({ material, loading, error }) => {
    return (
        <div className={styles.Wrapper}>
            <div className={styles.ImageWrapper}>
                <Image
                    className={styles.Image}
                    alt={"img"}
                    src={material?.thumbnail} //TODO change to heroImg
                />
                <div className={styles.Overlay}></div>
            </div>
            <div className={styles.ContentWrapper}>
                <ContentWrapper style={{ height: "100%" }}>
                    <div className={styles.Content}>
                        <div className={styles.Description}>
                            <Description
                                loading={loading}
                                error={error}
                                titleSize={"1.6em"}
                                {...material}
                                prelegents={null}
                                categories={null}
                            />
                        </div>
                        <div className={styles.MobileDescription}>
                            <Description
                                loading={loading}
                                error={error}
                                titleSize={"1.6em"}
                                {...material}
                                prelegents={null}
                                categories={null}
                                description={null}
                            />
                        </div>
                        {!loading && error == null ? (
                            <div className={styles.Actions}>
                                <PlayButton
                                    className={styles.ActionButton}
                                    url={`/${material?.groupInUrlName}/${material?.nameInUrl}`}
                                />
                                <ShareButton
                                    className={styles.ActionButton}
                                    pathname={`/${material?.groupInUrlName}/${material?.nameInUrl}`}
                                />
                                <FavoriteButton
                                    className={styles.ActionButton}
                                    nameInUrl={material?.nameInUrl}
                                    type={FavoriteButtonType.Episode}
                                />
                            </div>
                        ) : null}
                    </div>
                </ContentWrapper>
            </div>
        </div>
    );
};

export { HeroFeaturedMaterial };
