import { del, endpointBuilder, get, post } from "./axios";

// const search = async (params: { phrase: string, amount?: string, filters?: string[] }) => {
//     return get(endpointBuilder(`/MultimediaMaterials/search`, params))
// }

const getMultimediaMaterials = async (args: {
    token: string;
    params: {
        phrase?: string;
        amount?: number;
        filters?: string[];
    };
}) => {
    return get(endpointBuilder(`/multimediamaterials`, args.params), args.token);
};

const getMultimediaMaterial = async (args: { token: string; materialName: string }) => {
    return get(endpointBuilder(`/multimediamaterials/${args.materialName}`), args.token);
};

const getRandomMultimediaMaterial = async (args: { token: string; params?: { filters?: string[] } }) => {
    return get(endpointBuilder(`/multimediamaterials/random`, args.params), args.token);
};

const getFeaturedMultimediaMaterials = async (args: { token: string }) => {
    return get(endpointBuilder(`/multimediamaterials/featured`), args.token);
};

const getNewMultimediaMaterials = async (args: { token: string; params?: { amount?: number } }) => {
    return get(endpointBuilder(`/multimediamaterials/news`, args.params), args.token);
};

const getFavoriteMultimediaMaterials = async (args: { token: string; params?: { amount?: number } }) => {
    return get(endpointBuilder(`/multimediamaterials/favorites`, args.params), args.token);
};

const addFavoriteMultimediaMaterials = async (args: { token: string; nameInUrl: string }) => {
    return post(`/favorites/materials`, { nameInUrl: args.nameInUrl }, args.token);
};

const removeFavoriteMultimediaMaterials = async (args: { token: string; nameInUrl: string }) => {
    return del(`/favorites/materials/${args.nameInUrl}`, args.token);
};

export {
    getMultimediaMaterials,
    getMultimediaMaterial,
    getRandomMultimediaMaterial,
    getFeaturedMultimediaMaterials,
    getNewMultimediaMaterials,
    getFavoriteMultimediaMaterials,
    addFavoriteMultimediaMaterials,
    removeFavoriteMultimediaMaterials,
};
