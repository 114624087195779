import { useCallback, useEffect } from "react";
import { selectLoggedIn, selectToken, setUserLoggedInAndTokenStates } from "redux/slices/userSlice";
import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";
import { useHcaSdk } from "./useHcaSdk";

const useAuth = () => {
    const loggedIn = useAppSelector(selectLoggedIn);
    const token = useAppSelector(selectToken);

    const { hcaSdk, hcaSdkReadyToUse } = useHcaSdk();

    const signOut = useCallback(() => {
        console.log("signOut");
        if (!hcaSdkReadyToUse) return;
        hcaSdk.signOut();
    }, [hcaSdkReadyToUse]);

    console.log("loggedIn", loggedIn, token);

    return { loggedIn, token, signOut };
};

const useAuthConfiguration = () => {
    const dispatch = useAppDispatch();
    const { hcaSdk, hcaSdkReadyToUse } = useHcaSdk();

    const handleLogin = useCallback(
        (acc: any) => {
            console.log("handleLogin");
            if (!hcaSdkReadyToUse) return;
            hcaSdk.getAccessTokenSilent();
        },
        [hcaSdk],
    );

    const setToken = useCallback(
        (res: any) => {
            console.log("setToken");
            if (!hcaSdkReadyToUse) return;
            dispatch(setUserLoggedInAndTokenStates({ loggedIn: hcaSdk.isAccountLogged(), token: res.idToken }));
        },
        [hcaSdk],
    );

    const handleError = useCallback(() => {
        console.log("handleError");
        if (!hcaSdkReadyToUse) return;
        hcaSdk.signOut();
    }, [hcaSdk]);

    const handleCancel = useCallback(() => {
        console.log("handleCancel");
    }, []);

    useEffect(() => {
        if (!hcaSdkReadyToUse) return;
        console.log("useEffect");
        hcaSdk.setHcaSdkConfig(
            "f0c10490-f302-412c-af8c-03d34a30d478",
            ["https://auth.onekeyconnect.com/x/profile.extended"],
            true,
        );
        hcaSdk.setErrorCallBack(handleError);
        hcaSdk.setCancelCallBack(handleCancel);
        hcaSdk.setLoginCallBack(handleLogin);
        hcaSdk.setTokenCallBack(setToken);

        // if (!hcaSdk.isAccountLogged()) hcaSdk.signIn();
    }, [hcaSdkReadyToUse, handleLogin, setToken]);

    return null;
};

export { useAuth, useAuthConfiguration };
