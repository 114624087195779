import styles from 'styles/Modal.module.css';

interface ModalProps<ELEM_TYPE> {
  itemProps?: ELEM_TYPE;
  renderItem: (itemProps?: ELEM_TYPE) => React.ReactNode;
  initWidth: number;
  initHeight: number;
  targetWidth: number;
  targetHeight: number;
  left?: string | number;
  right?: string | number;
  top?: string | number;
  transform?: string;
  open: boolean;
  position?: string;
}

const Modal = <T extends unknown>({
  itemProps,
  renderItem,
  left,
  top,
  right,
  transform,
  initWidth,
  initHeight,
  targetWidth,
  targetHeight,
  open,
  position = 'absolute',
}: ModalProps<T>) => {
  return (
    <div
      style={
        {
          '--initItemWidth': `${initWidth}px`,
          '--initItemHeight': `${initHeight}px`,
          '--targetItemWidth': `${targetWidth}px`,
          '--targetItemHeight': `${targetHeight}px`,
          fontSize: Math.ceil(initHeight / 30) + 'px',
        } as React.CSSProperties
      }
    >
      <div
        className={`
        ${open ? styles.Show : styles.Hide}
        ${styles.Wrapper}`}
        style={
          {
            left,
            top,
            right,
            transform,
            position: position,
          } as React.CSSProperties
        }
      >
        <div className={styles.Content}>{renderItem(itemProps)}</div>
      </div>
    </div>
  );
};

export { Modal };
