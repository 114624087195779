import { configureStore } from "@reduxjs/toolkit";
import locationSlice from "./slices/locationSlice";
import categorySlice from "./slices/categorySlice";
import userSlice from "./slices/userSlice";
import sliderSlice from "./slices/sliderSlice";
import modalSlice from "./slices/modalSlice";
import preloadSlice from "./slices/preloadSlice";
import toastSlice from "./slices/toastSlice";

const store = configureStore({
    reducer: {
        location: locationSlice,
        category: categorySlice,
        user: userSlice,
        slider: sliderSlice,
        modal: modalSlice,
        preload: preloadSlice,
        toast: toastSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
