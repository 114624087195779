import { useCallback, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import * as SliderSlice from "redux/slices/sliderSlice";
import { useAppSelector } from "./useAppSelector";
import { useListener } from "./useListener";

const useSliderManager = () => {
    const dispatch = useDispatch();
    const allInitialSettings = useAppSelector(SliderSlice.selectAllInitialSettings);

    const updateSettings = useCallback((key: string, settings: SliderSlice.SliderElementCurrentSettings) => {
        dispatch(SliderSlice.updateCurrentSettings({ key, settings }));
    }, []);

    const addSettings = useCallback((key: string, settings: SliderSlice.SliderElementInitialSettings) => {
        dispatch(SliderSlice.updateInitialSettings({ key, settings }));
    }, []);

    const handleUpdateOnWindowWidthChange = useCallback(() => {
        const keys = Object.keys(allInitialSettings);
        const windowWidth = window.innerWidth;
        const scrollWidth = 8;

        for (const key of keys) {
            const elementSettings = allInitialSettings[key];

            if (elementSettings === undefined) return;

            const windowWidthSettings = elementSettings.displayVariations;

            for (const settings of windowWidthSettings) {
                if (settings.maximumWindowWidth ? windowWidth <= settings.maximumWindowWidth : true) {
                    const newElementWidth =
                        (windowWidth -
                            scrollWidth -
                            settings.padding * 2 -
                            (settings.numberOfElementsToDisplay - 1) * settings.gap) /
                        settings.numberOfElementsToDisplay;

                    const newElementHeight =
                        newElementWidth *
                        (settings.heightToWidthRatio !== undefined
                            ? settings.heightToWidthRatio
                            : elementSettings.defaultheightToWidthRatio);

                    updateSettings(key, {
                        elemWidth: newElementWidth,
                        elemHeight: newElementHeight,
                        gap: settings.gap,
                        padding: settings.padding,
                        mobile: settings.mobile !== undefined ? settings.mobile : false,
                        numberOfElementsToDisplay: settings.numberOfElementsToDisplay,
                        numberOfElementsToSlide: settings.numberOfElementsToSlide,
                    });

                    break;
                }
            }
        }
    }, [JSON.stringify(allInitialSettings)]);

    useListener(window, "resize", handleUpdateOnWindowWidthChange, [handleUpdateOnWindowWidthChange]);
    useLayoutEffect(() => {
        handleUpdateOnWindowWidthChange();
    }, [handleUpdateOnWindowWidthChange]);

    return { addSettings };
};

export { useSliderManager };
