import { getImage } from "api/image";
import { FC, ImgHTMLAttributes, useCallback, useEffect, useState } from "react";
import { BsFillImageFill } from "react-icons/bs";

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
    fetchFromLocalServer?: boolean;
}

const Image: FC<ImageProps> = (props) => {
    const [isError, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [blob, setBlob] = useState<Blob | null>(null);

    useEffect(() => {
        if (props.src !== "" && props.src !== undefined && props.src !== null) {
            setLoading(true);
            setError(false);
            if (!props.fetchFromLocalServer) {
                getImage(props.src)
                    .then((res) => res.data)
                    .then((blob) => {
                        setBlob(blob);
                    })
                    .then(() => setLoading(false))
                    .catch(() => {
                        setError(true);
                    });
            }
        } else {
            setError(true);
        }
    }, [props.src]);

    const handleError = useCallback(() => {
        setError(true);
        setLoading(false);
    }, [props.src, props.alt]);

    const handleLoad = useCallback(() => {
        setLoading(false);
        setError(false);
    }, []);

    return (isError && !loading) || (blob === null && !props.fetchFromLocalServer) ? (
        <div
            className={props.className}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <BsFillImageFill
                style={{
                    fontSize: "6rem",
                    color: "var(--color-13)",
                }}
            />
        </div>
    ) : (
        <>
            <img
                {...props}
                src={props.fetchFromLocalServer ? props.src : blob !== null ? URL.createObjectURL(blob) : ""}
                onLoad={props.fetchFromLocalServer ? undefined : handleLoad}
                onError={handleError}
                onAbort={handleError}
            />
        </>
    );
};

export { Image };
