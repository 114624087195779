import { EPISODE_SLIDER_NAME } from "components/Sliders/EpisodeSlider";
import { MATERIAL_SLIDER_NAME } from "components/Sliders/MaterialSlider";
import { useLayoutEffect } from "react";
import { useSliderManager } from "./useSliderManager";

const useSlidersInitialization = () => {
    const { addSettings } = useSliderManager();
    useLayoutEffect(() => {
        addSettings(EPISODE_SLIDER_NAME, {
            displayVariations: [
                {
                    maximumWindowWidth: 500,
                    numberOfElementsToDisplay: 1.15,
                    gap: 10,
                    padding: 20,
                    mobile: true,
                    numberOfElementsToSlide: 1,
                },
                {
                    maximumWindowWidth: 600,
                    numberOfElementsToDisplay: 2,
                    gap: 10,
                    padding: 30,
                    mobile: true,
                    numberOfElementsToSlide: 2,
                },
                {
                    maximumWindowWidth: 800,
                    numberOfElementsToDisplay: 2,
                    gap: 10,
                    padding: 30,
                    mobile: true,
                    numberOfElementsToSlide: 2,
                },
                {
                    maximumWindowWidth: 1000,
                    numberOfElementsToDisplay: 3,
                    gap: 10,
                    padding: 40,
                    mobile: true,
                    numberOfElementsToSlide: 3,
                },
                {
                    maximumWindowWidth: 1200,
                    numberOfElementsToDisplay: 3,
                    numberOfElementsToSlide: 3,
                    gap: 10,
                    padding: 60,
                },
                {
                    maximumWindowWidth: 1550,
                    numberOfElementsToDisplay: 4,
                    numberOfElementsToSlide: 4,
                    gap: 15,
                    padding: 60,
                },
                {
                    maximumWindowWidth: 1920,
                    numberOfElementsToDisplay: 5,
                    numberOfElementsToSlide: 5,
                    gap: 15,
                    padding: 60,
                },
                {
                    maximumWindowWidth: 2400,
                    numberOfElementsToDisplay: 7,
                    numberOfElementsToSlide: 7,
                    gap: 15,
                    padding: 60,
                },
                {
                    numberOfElementsToDisplay: 10,
                    numberOfElementsToSlide: 10,
                    gap: 15,
                    padding: 60,
                },
            ],
            defaultheightToWidthRatio: 0.85,
        });
        addSettings(MATERIAL_SLIDER_NAME, {
            displayVariations: [
                {
                    maximumWindowWidth: 500,
                    numberOfElementsToDisplay: 1.15,
                    gap: 10,
                    padding: 20,
                    mobile: true,
                    numberOfElementsToSlide: 1,
                },
                {
                    maximumWindowWidth: 600,
                    numberOfElementsToDisplay: 2,
                    gap: 10,
                    padding: 30,
                    mobile: true,
                    numberOfElementsToSlide: 2,
                },
                {
                    maximumWindowWidth: 800,
                    numberOfElementsToDisplay: 2,
                    gap: 10,
                    padding: 30,
                    mobile: true,
                    numberOfElementsToSlide: 2,
                },
                {
                    maximumWindowWidth: 1000,
                    numberOfElementsToDisplay: 3,
                    gap: 10,
                    padding: 40,
                    mobile: true,
                    numberOfElementsToSlide: 3,
                },
                {
                    maximumWindowWidth: 1200,
                    numberOfElementsToDisplay: 3,
                    numberOfElementsToSlide: 3,
                    gap: 10,
                    padding: 60,
                },
                {
                    maximumWindowWidth: 1550,
                    numberOfElementsToDisplay: 4,
                    numberOfElementsToSlide: 4,
                    gap: 15,
                    padding: 60,
                },
                {
                    maximumWindowWidth: 1920,
                    numberOfElementsToDisplay: 5,
                    numberOfElementsToSlide: 5,
                    gap: 15,
                    padding: 60,
                },
                {
                    maximumWindowWidth: 2400,
                    numberOfElementsToDisplay: 7,
                    numberOfElementsToSlide: 7,
                    gap: 15,
                    padding: 60,
                },
                {
                    numberOfElementsToDisplay: 10,
                    numberOfElementsToSlide: 10,
                    gap: 15,
                    padding: 60,
                },
            ],
            defaultheightToWidthRatio: 0.7,
        });
    }, []);
};

export { useSlidersInitialization };
