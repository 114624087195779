import Group from "interfaces/data/Group.interface";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import styles from "styles/MaterialCard.module.css";
import { SliderItemPosition } from "./Sliders/Slider";
import { Image } from "components/Image";
import { useNavigate } from "react-router-dom";
import { useModalManager } from "hooks/useModal";
import { MATERIAL_MODAL_NAME } from "./Modals/MaterialModal";
import { FavoriteButton, FavoriteButtonType, OpenButton, PlayButton, ShareButton } from "./Button";
import { Description } from "./Description";
import { MaterialDescription } from "./MaterialDescription";
import Multimedia from "interfaces/data/Multimedia.interface";
import { List } from "./List/List";

interface MaterialCardProps {
    width: number;
    height: number;
    data: Group;
    itemPosition?: SliderItemPosition;
    mobile: boolean;
}

const MaterialCard: FC<MaterialCardProps> = ({ width, height, data, mobile, itemPosition }) => {
    const navigate = useNavigate();
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const { updateModalData } = useModalManager();
    const [mouseIn, setMouseIn] = useState(false);

    const openModal = useCallback(() => {
        const rect = wrapperRef.current?.getBoundingClientRect();
        if (rect !== undefined) {
            updateModalData(MATERIAL_MODAL_NAME, {
                x: rect.x,
                y: rect.y + window.scrollY,
                open: true,
                initWidth: width,
                initHeight: height,
                additionalData: {
                    data: data,
                    itemPosition: itemPosition,
                },
                mobile,
            });
        }
    }, [wrapperRef.current, width, height, itemPosition, JSON.stringify(data)]);

    useEffect(() => {
        var timeout: NodeJS.Timeout;
        if (mouseIn) {
            timeout = setTimeout(() => {
                openModal();
            }, 500);
        }
        return () => clearTimeout(timeout);
    }, [mouseIn]);

    const handleClick = useCallback(() => {
        navigate(`/${data.nameInUrl}`);
    }, [mobile, data.nameInUrl]);

    return (
        <div
            style={{ width, height, fontSize: `${height / 15.5}px` }}
            className={styles.Wrapper}
            onClick={handleClick}
            ref={wrapperRef}
        >
            <div className={styles.ThumbnailWrapper}>
                <Image src={data.thumbnail} alt="thumbnail" className={styles.Thumbnail} />
            </div>
            <div className={styles.AdditionalInfo}>
                <div
                    className={styles.ShowModal}
                    onMouseOver={() => setMouseIn(true)}
                    onMouseOut={() => setMouseIn(false)}
                >
                    Hover to expand
                </div>
                {data.multimedia.length > 1 ? (
                    <div className={styles.EpisodesAmount}>
                        <span>{data.multimedia.length}</span> episodes
                    </div>
                ) : null}
            </div>
        </div>
    );
};

interface MaterialModalCardProps {
    data?: Group;
    mobile: boolean;
}

const MaterialModalCard: FC<MaterialModalCardProps> = ({ mobile, data }) => {
    const { closeModal } = useModalManager();
    const descriptionRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (descriptionRef.current) {
            descriptionRef.current.scrollTo(0, 0);
        }
    }, [data?.nameInUrl, descriptionRef]);

    return (
        <div className={mobile ? `${styles.ModalWrapper} ${styles.ModalWrapperMobile}` : styles.ModalWrapper}>
            <div className={`${styles.Row} ${styles.RowTop}`}>
                <div className={styles.ModalThumbnailWrapper}>
                    <Image className={styles.ModalThumbnail} src={data?.thumbnail} />
                </div>
                <div className={styles.Overlay}>
                    <div className={styles.Title}>{data?.title}</div>
                </div>
            </div>
            <div className={`${styles.Row} ${styles.RowBottom}`}>
                <div className={styles.Actions}>
                    {/* {data?.multimedia.length === 1 ? (
            <PlayButton url={`/${data?.nameInUrl}/${data.multimedia[0].nameInUrl}`} />
          ) : ( */}
                    <OpenButton url={`/${data?.nameInUrl}`} />
                    {/* )} */}
                    <ShareButton pathname={`/${data?.nameInUrl}`} />
                    <FavoriteButton nameInUrl={data?.nameInUrl} type={FavoriteButtonType.Material} />
                </div>
            </div>
            {data ? (
                <div ref={descriptionRef} className={`${styles.Row} ${styles.DescriptionRow}`}>
                    <MaterialDescription data={{ ...data, categories: {} }} loading={false} error={null} />
                    <div className={styles.Episodes}>
                        <div className={styles.EpisodesHeading}>Episodes</div>
                        <List padding={"0"} gap={".8em"}>
                            {data.multimedia.map((item, index) => (
                                <EpisodesListItem data={item} key={index} />
                            ))}
                        </List>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

interface EpisodesListItemProps {
    data: Multimedia;
}

const EpisodesListItem: FC<EpisodesListItemProps> = ({ data }) => {
    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        navigate(`/${data.groupInUrlName}/${data.nameInUrl}`);
    }, [data.nameInUrl]);

    return (
        <div className={styles.EpisodesListItem} onClick={handleClick}>
            <div className={styles.EpisodeThumbnail}>
                <Image src={data.thumbnail} alt={data.nameInUrl} className={styles.EpisodeThumbnailImage} />
            </div>
            <div className={styles.EpisodeTitle}>{data.title}</div>
        </div>
    );
};

export { MaterialCard, MaterialModalCard };
