import { useAppDispatch } from "./useAppDispatch";
import * as toastSlice from "redux/slices/toastSlice";

const useToast = () => {
    const dispatch = useAppDispatch();

    const addToast = (toast: {
        uid: string;
        message: string;
        duration?: number | null;
        type?: toastSlice.ToastType;
    }) => {
        dispatch(
            toastSlice.addToast({
                ...toast,
                duration: toast.duration !== undefined ? toast.duration : 3000,
                type: toast.type !== undefined ? toast.type : toastSlice.ToastType.INFO,
            }),
        );
    };

    const removeToast = (uid: string) => {
        dispatch(toastSlice.removeToast(uid));
    };

    return { addToast, removeToast };
};

export { useToast };
