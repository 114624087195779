import { getGroups, getNewsGroup } from "api/group";
import { getRandomMultimediaMaterial } from "api/multimediaMaterials";
import { HeroFeaturedMaterial } from "components/HeroFeaturedMaterial";
import { Page } from "components/Page";
import { PageContent } from "components/PageContent";
import { EpisodeSlider } from "components/Sliders/EpisodeSlider";
import { MaterialSlider } from "components/Sliders/MaterialSlider";
import { TitledSection } from "components/TitledSection";
import withPageZoneData from "hocs/withPageZoneData";
import { withScrollUp } from "hocs/withScrollUp";
import { useFetch } from "hooks/useFetch";
import { useLocation } from "hooks/useLocation";
import useRequest from "hooks/useRequest";
import Group from "interfaces/data/Group.interface";
import Multimedia from "interfaces/data/Multimedia.interface";
import { FC, useEffect, useMemo } from "react";

const New: FC = () => {
    const { setLocation } = useLocation();
    const heroFeaturedMaterialData = useRequest<Multimedia>(({ token }) => getRandomMultimediaMaterial({ token }));
    const newMaterials = useRequest<Group[]>(({ token }) => getNewsGroup({ params: { amount: 16 }, token }));
    useEffect(() => {
        setLocation({
            name: "New materials",
            prevLocation: undefined,
            navLinks: [],
        });
    }, []);

    useFetch(() => {
        heroFeaturedMaterialData.sendRequest();
        newMaterials.sendRequest();
    }, []);

    const materialsSliders = useMemo(() => {
        const data = newMaterials.response.data;
        if (data !== null) {
            return data.map((item, index) => {
                return item.multimedia.length > 1 ? (
                    <TitledSection title={item.title} linkText={"Open material"} linkTo={`/${item.nameInUrl}`}>
                        <EpisodeSlider
                            elements={item.multimedia}
                            error={newMaterials.response.error}
                            loading={newMaterials.response.loading}
                        />
                    </TitledSection>
                ) : null;
            });
        }
    }, [newMaterials.response.data]);

    return (
        <>
            <HeroFeaturedMaterial
                loading={heroFeaturedMaterialData.response.loading}
                material={heroFeaturedMaterialData.response.data}
                error={heroFeaturedMaterialData.response.error}
            />
            <PageContent>
                <TitledSection title="New materials">
                    <MaterialSlider
                        loading={newMaterials.response.loading}
                        elements={newMaterials.response.data}
                        error={newMaterials.response.error}
                    />
                </TitledSection>
                {materialsSliders}
            </PageContent>
        </>
    );
};

export default withScrollUp(New);
