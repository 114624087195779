import { addFavoriteGroup, removeFavoriteGroup } from "api/group";
import { addFavoriteMultimediaMaterials, removeFavoriteMultimediaMaterials } from "api/multimediaMaterials";
import { useAppSelector } from "hooks/useAppSelector";
import { useModalManager } from "hooks/useModal";
import useRequest from "hooks/useRequest";
import { useToast } from "hooks/useToast";
import { FC, useCallback } from "react";
import { BsFolder2Open, BsHeart, BsHeartFill, BsPlayFill, BsShare } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    addFavoriteEpisodeCode,
    addFavoriteMaterialCode,
    isFavorite,
    removeFavoriteEpisodeCode,
    removeFavoriteMaterialCode,
} from "redux/slices/userSlice";
import styles from "styles/Button.module.css";
import { guid } from "utils/guid";
import { SHARE_MODAL_NAME } from "./Modals/ShareModal";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children?: React.ReactNode;
}

const Button: FC<ButtonProps> = (props) => {
    return (
        <button
            type={props.type || "button"}
            {...props}
            className={props.className ? `${props.className} ${styles.Button}` : styles.Button}
        >
            {props.children}
        </button>
    );
};

const CircleButton: FC<ButtonProps> = (props) => {
    return (
        <button
            type="button"
            {...props}
            className={props.className ? `${styles.CircleButton} ${props.className}` : styles.CircleButton}
        >
            {props.children}
        </button>
    );
};

interface PlayButtonProps {
    className?: string;
    disabled?: boolean;
    url: string;
}

const PlayButton: FC<PlayButtonProps> = (props) => {
    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        navigate(props.url);
    }, [props.url]);

    return (
        <Button
            onClick={handleClick}
            disabled={props.disabled}
            className={props.className ? `${styles.PlayButton} ${props.className}` : styles.PlayButton}
        >
            Play <BsPlayFill />
        </Button>
    );
};

interface OpenButtonProps {
    className?: string;
    disabled?: boolean;
    url: string;
}

const OpenButton: FC<OpenButtonProps> = (props) => {
    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        navigate(props.url);
    }, [props.url]);

    return (
        <Button
            onClick={handleClick}
            disabled={props.disabled}
            className={props.className ? `${styles.PlayButton} ${props.className}` : styles.PlayButton}
        >
            Open <BsFolder2Open />
        </Button>
    );
};

const CirclePlayButton: FC<PlayButtonProps> = (props) => {
    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        navigate(props.url);
    }, [props.url]);

    return (
        <CircleButton
            onClick={handleClick}
            disabled={props.disabled}
            className={props.className ? `${styles.PlayButton} ${props.className}` : styles.PlayButton}
        >
            <BsPlayFill />
        </CircleButton>
    );
};

interface ShareButtonProps {
    className?: string;
    disabled?: boolean;
    pathname: string;
}

const ShareButton: FC<ShareButtonProps> = (props) => {
    const { updateModalData } = useModalManager();

    const openModal = useCallback(() => {
        updateModalData(SHARE_MODAL_NAME, {
            open: true,
            additionalData: {
                url: `${window.location.protocol}//${window.location.host}${props.pathname}`,
            },
        });
    }, [props.pathname]);

    return (
        <Button
            disabled={props.disabled}
            className={props.className ? `${props.className} ${styles.ShareButton}` : styles.ShareButton}
            onClick={openModal}
        >
            Share <BsShare />
        </Button>
    );
};

const CircleShareButton: FC<ShareButtonProps> = (props) => {
    const { updateModalData } = useModalManager();

    const openModal = useCallback(() => {
        updateModalData(SHARE_MODAL_NAME, {
            open: true,
            additionalData: {
                url: `${window.location.protocol}//${window.location.host}${props.pathname}`,
            },
        });
    }, [props.pathname]);

    return (
        <CircleButton
            disabled={props.disabled}
            className={props.className ? `${styles.ShareButton} ${props.className}` : styles.ShareButton}
            onClick={openModal}
            title="Share"
        >
            <BsShare />
        </CircleButton>
    );
};

export enum FavoriteButtonType {
    Material,
    Episode,
}

interface FavoriteButtonProps {
    className?: string;
    disabled?: boolean;
    nameInUrl?: string;
    type: FavoriteButtonType;
}

const FavoriteButton: FC<FavoriteButtonProps> = (props) => {
    const { isMaterialFavoriteAlready, handleClick } = useFavoriteButton(props.nameInUrl || "", props.type);

    return (
        <Button
            disabled={props.disabled}
            className={props.className ? `${props.className} ${styles.FavoriteButton}` : styles.FavoriteButton}
            onClick={handleClick}
        >
            {!isMaterialFavoriteAlready ? (
                <>
                    Add to favorites <BsHeart />{" "}
                </>
            ) : (
                <>
                    Remove from favorites <BsHeartFill />{" "}
                </>
            )}
        </Button>
    );
};

const CircleFavoriteButton: FC<FavoriteButtonProps> = (props) => {
    const { isMaterialFavoriteAlready, handleClick } = useFavoriteButton(props.nameInUrl || "", props.type);

    return (
        <CircleButton
            disabled={props.disabled}
            className={props.className ? `${styles.FavoriteButton} ${props.className}` : styles.FavoriteButton}
            title="Add to favorites"
            onClick={handleClick}
        >
            {!isMaterialFavoriteAlready ? <BsHeart /> : <BsHeartFill />}
        </CircleButton>
    );
};

const useFavoriteButton = (nameInUrl: string, type: FavoriteButtonType) => {
    const dispatch = useDispatch();
    const { addToast } = useToast();

    const addToFavorite = useRequest(({ token }) =>
        type === FavoriteButtonType.Episode
            ? addFavoriteMultimediaMaterials({ token, nameInUrl: nameInUrl })
            : addFavoriteGroup({ token, nameInUrl: nameInUrl }),
    );

    const removeFromFavorite = useRequest(({ token }) =>
        type === FavoriteButtonType.Episode
            ? removeFavoriteMultimediaMaterials({ token, nameInUrl: nameInUrl })
            : removeFavoriteGroup({ token, nameInUrl: nameInUrl }),
    );

    const isMaterialFavoriteAlready = useAppSelector((state) => isFavorite(state, type, nameInUrl));

    const handleClick = () => {
        if (isMaterialFavoriteAlready) {
            removeFromFavorite
                .sendRequest()
                .then((e) =>
                    type === FavoriteButtonType.Episode
                        ? dispatch(removeFavoriteEpisodeCode(nameInUrl))
                        : dispatch(removeFavoriteMaterialCode(nameInUrl)),
                )
                .then(() => addToast({ uid: guid(), message: "Removed from favorites" }));
        } else {
            addToFavorite
                .sendRequest()
                .then((e) =>
                    type === FavoriteButtonType.Episode
                        ? dispatch(addFavoriteEpisodeCode(nameInUrl))
                        : dispatch(addFavoriteMaterialCode(nameInUrl)),
                )
                .then(() => addToast({ uid: guid(), message: "Added to favorites" }));
        }
    };

    return { isMaterialFavoriteAlready, handleClick };
};

export {
    Button,
    CircleButton,
    PlayButton,
    CirclePlayButton,
    CircleShareButton,
    CircleFavoriteButton,
    ShareButton,
    FavoriteButton,
    OpenButton,
};
