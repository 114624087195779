import { getGroups } from "api/group";
import { getRandomMultimediaMaterial } from "api/multimediaMaterials";
import { HeroFeaturedMaterial } from "components/HeroFeaturedMaterial";
import { Page } from "components/Page";
import { PageContent } from "components/PageContent";
import { EpisodeSlider } from "components/Sliders/EpisodeSlider";
import { MaterialSlider } from "components/Sliders/MaterialSlider";
import { TitledSection } from "components/TitledSection";
import withPageZoneData from "hocs/withPageZoneData";
import { withScrollUp } from "hocs/withScrollUp";
import { useFetch } from "hooks/useFetch";
import { useLocation } from "hooks/useLocation";
import useRequest from "hooks/useRequest";
import Category from "interfaces/data/Category.interface";
import Group from "interfaces/data/Group.interface";
import Multimedia from "interfaces/data/Multimedia.interface";
import { FC, useEffect, useMemo } from "react";

const Zone: FC<{ pageZoneData?: Category }> = ({ pageZoneData }) => {
    const { setLocation } = useLocation();
    const heroFeaturedMaterialData = useRequest<Multimedia>(({ token }) =>
        getRandomMultimediaMaterial({
            params: { filters: [pageZoneData?.id.toString() || "-1"] },
            token,
        }),
    );
    const zoneMaterials = useRequest<Group[]>(({ token }) =>
        getGroups({ params: { amount: 16, filters: [pageZoneData?.id?.toString() || "-1"] }, token }),
    );
    useEffect(() => {
        setLocation({
            name: pageZoneData?.displayName || "",
            prevLocation:
                pageZoneData?.parentName && pageZoneData.parentDisplayName && pageZoneData.parentName !== "zones"
                    ? { name: pageZoneData?.parentDisplayName, url: `/category/${pageZoneData?.parentName}` }
                    : undefined,
            navLinks:
                pageZoneData?.children.map((item) => ({
                    name: item.displayName || "",
                    url: `/category/${item.name}`,
                })) || [],
        });
    }, [pageZoneData?.id]);

    useFetch(() => {
        heroFeaturedMaterialData.sendRequest();
        zoneMaterials.sendRequest();
    }, [pageZoneData?.id]);

    const materialsSliders = useMemo(() => {
        const data = zoneMaterials.response.data;
        if (data !== null) {
            return data.map((item, index) => {
                return item.multimedia.length > 1 ? (
                    <TitledSection
                        key={index}
                        title={item.title}
                        linkText={"Open material"}
                        linkTo={`/${item.nameInUrl}`}
                    >
                        <EpisodeSlider
                            elements={item.multimedia || null}
                            error={zoneMaterials.response.error}
                            loading={zoneMaterials.response.loading}
                        />
                    </TitledSection>
                ) : null;
            });
        }
    }, [zoneMaterials.response.data]);

    return (
        <>
            <HeroFeaturedMaterial
                loading={heroFeaturedMaterialData.response.loading}
                material={heroFeaturedMaterialData.response.data}
                error={heroFeaturedMaterialData.response.error}
            />
            <PageContent>
                <TitledSection title="Materials">
                    <MaterialSlider
                        elements={zoneMaterials.response.data}
                        loading={zoneMaterials.response.loading}
                        error={zoneMaterials.response.error}
                    />
                </TitledSection>
                {materialsSliders}
            </PageContent>
        </>
    );
};

export default withScrollUp(withPageZoneData(Zone));
