import { FC, useCallback } from 'react';
import { BsPlayFill, BsX, BsXSquare } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import styles from 'styles/VideoPlayerWithControlls.module.css';
import {
  Button,
  CircleButton,
  CircleFavoriteButton,
  CircleShareButton,
  FavoriteButtonType,
} from './Button';
import { VideoPlayer } from './VideoPlayer';

export interface VideoPlayerWithControllsProps {
  onnetworkId: string;
  prevVideoNameInUrl: string | undefined;
  nextVideoNameInUrl: string | undefined;
  currentVideoNameInUrl: string;
  playlistNameInUrl: string;
}

const VideoPlayerWithControlls: FC<VideoPlayerWithControllsProps> = ({
  onnetworkId,
  prevVideoNameInUrl,
  nextVideoNameInUrl,
  currentVideoNameInUrl,
  playlistNameInUrl,
}) => {
  const navigate = useNavigate();
  const navigateToPrevVideo = useCallback(() => {
    if (prevVideoNameInUrl !== undefined) navigate(`/${playlistNameInUrl}/${prevVideoNameInUrl}`);
  }, [prevVideoNameInUrl, playlistNameInUrl]);
  const navigateToNextVideo = useCallback(() => {
    if (nextVideoNameInUrl !== undefined) navigate(`/${playlistNameInUrl}/${nextVideoNameInUrl}`);
  }, [nextVideoNameInUrl, playlistNameInUrl]);

  return (
    <div className={styles.Wrapper}>
      <div className={styles.VideoPlayer}>
        <VideoPlayer onnetworkId={onnetworkId} />
      </div>
      <div className={styles.Controlls}>
        <CircleButton
          onClick={navigateToPrevVideo}
          disabled={prevVideoNameInUrl === undefined}
          title='Previous episode'
        >
          <BsPlayFill style={{ fontSize: '1em', transform: 'scaleX(-1)' }} />
        </CircleButton>
        <CircleShareButton pathname={window.location.pathname} />
        <CircleFavoriteButton
          nameInUrl={currentVideoNameInUrl}
          type={FavoriteButtonType.Episode}
        />
        <CircleButton
          onClick={navigateToNextVideo}
          disabled={nextVideoNameInUrl === undefined}
          title='Next episode'
        >
          <BsPlayFill style={{ fontSize: '1em' }} />
        </CircleButton>
        {/* <CircleButton
          onClick={closePlayer}
          className={styles.CloseButton}
          title='Close player'
        >
          <BsXSquare style={{ fontSize: '1em' }} />
        </CircleButton> */}
      </div>
    </div>
  );
};

export { VideoPlayerWithControlls };
