import { ContentWrapper } from "components/ContentWrapper";
import ErrorMessage from "components/ErrorMessage";
import { ErrorProps } from "components/ErrorMessage";

const withErrorMessage = <DATA extends {}, T extends DATA>(
    Component: React.ComponentType<T & { error?: any }>,
    withContentWrapper: boolean = false,
    errorExtractor: (item: T & { error?: any }) => ErrorProps | null,
    arrayDataExtractor?: (item: T) => any,
) => {
    return (props: T & { error?: any }) => {
        const errorObject = errorExtractor(props);

        const doesArrayEmpty = () =>
            arrayDataExtractor !== undefined &&
            Array.isArray(arrayDataExtractor(props)) &&
            (arrayDataExtractor(props) as []).length === 0;

        const error =
            errorObject !== null ? (
                <ErrorMessage code={errorObject?.code} message={errorObject?.message} />
            ) : doesArrayEmpty() ? (
                <ErrorMessage
                    code={"There's nothing to show"}
                    message={"No search results for the given conditions."}
                />
            ) : null;

        return error == null ? (
            <Component {...props} />
        ) : withContentWrapper ? (
            <ContentWrapper>{error}</ContentWrapper>
        ) : (
            error
        );
    };
};

export { withErrorMessage };
