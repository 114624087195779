import { useModalManager } from "hooks/useModal";
import Multimedia from "interfaces/data/Multimedia.interface";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import styles from "styles/EpisodeCard.module.css";
import { SliderItemPosition } from "./Sliders/Slider";
import { Image } from "components/Image";
import { Button, CircleButton, FavoriteButton, FavoriteButtonType, PlayButton, ShareButton } from "./Button";
import { BsThreeDotsVertical, BsX } from "react-icons/bs";
import { DropDown } from "./DropDown";
import { durationFormatter } from "utils/duartionFormatter";
import { useNavigate } from "react-router-dom";
import { EPISODE_MODAL_NAME } from "./Modals/EpisodeModal";
import { Description } from "./Description";

interface EpisodeCardProps {
    width: number;
    height: number;
    data: Multimedia;
    itemPosition?: SliderItemPosition;
    mobile: boolean;
    active?: boolean;
}

const EpisodeCard: FC<EpisodeCardProps> = ({ width, height, data, itemPosition, mobile, active }) => {
    const { updateModalData } = useModalManager();
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const [mouseIn, setMouseIn] = useState(false);
    const [displayDropDown, setDisplayDropDown] = useState(false);
    const navigate = useNavigate();

    const openModal = useCallback(() => {
        const rect = wrapperRef.current?.getBoundingClientRect();
        if (rect !== undefined) {
            updateModalData(EPISODE_MODAL_NAME, {
                x: rect.x,
                y: rect.y + window.scrollY,
                open: true,
                initWidth: width,
                initHeight: height,
                additionalData: {
                    data: data,
                    itemPosition: itemPosition,
                },
                mobile,
            });
        }
    }, [wrapperRef.current, width, height, itemPosition, JSON.stringify(data)]);

    useEffect(() => {
        var timeout: NodeJS.Timeout;
        if (mouseIn) {
            timeout = setTimeout(() => {
                openModal();
            }, 500);
        }
        return () => clearTimeout(timeout);
    }, [mouseIn]);

    const handleClick = useCallback(() => {
        if (mobile) {
            openModal();
        } else {
            navigate(`/${data.groupInUrlName}/${data.nameInUrl}`);
        }
    }, [mobile, data.nameInUrl]);

    return (
        <div
            style={{
                width,
                height,
                fontSize: `${height / 20}px`,
            }}
            ref={wrapperRef}
            className={active === true ? `${styles.Wrapper} ${styles.Active}` : styles.Wrapper}
            onClick={handleClick}
            onMouseLeave={() => setDisplayDropDown(false)}
        >
            <div className={`${styles.Row} ${styles.RowTop}`}>
                <Image className={styles.Thumbnail} src={data.thumbnail} />
                <div className={styles.AdditionalInfo}>
                    <div
                        className={styles.ShowModal}
                        onMouseOver={() => setMouseIn(true)}
                        onMouseOut={() => setMouseIn(false)}
                    >
                        Hover to see description
                    </div>
                    <div className={styles.Duration}>{durationFormatter(data.duration)}</div>
                </div>
            </div>
            <div className={`${styles.Row} ${styles.RowBottom}`}>
                <div className={styles.Title}>{data.title}</div>
                <div className={styles.AdditionalActions}>
                    <Button
                        className={styles.DropdownButton}
                        onClick={(e) => {
                            e.stopPropagation();
                            setDisplayDropDown(!displayDropDown);
                        }}
                    >
                        {!displayDropDown ? (
                            <BsThreeDotsVertical style={{ fontSize: "1.2em", color: "var(--color-4)" }} />
                        ) : (
                            <BsX style={{ fontSize: "1.5em", color: "var(--color-4)" }} />
                        )}
                    </Button>
                    <DropDown isOpen={displayDropDown} wrapperClassName={styles.AdditionalActionsDropdown}>
                        <FavoriteButton
                            className={styles.AdditionalActionsButton}
                            type={FavoriteButtonType.Episode}
                            nameInUrl={data?.nameInUrl}
                        />
                        <ShareButton
                            pathname={`/${data?.groupInUrlName}/${data?.nameInUrl}`}
                            className={styles.AdditionalActionsButton}
                        />
                    </DropDown>
                </div>
            </div>
        </div>
    );
};

interface EpisodeModalCardProps {
    data?: Multimedia;
    mobile: boolean;
}

const EpisodeModalCard: FC<EpisodeModalCardProps> = ({ data, mobile }) => {
    const { closeModal } = useModalManager();

    return (
        <div className={mobile ? `${styles.ModalWrapper} ${styles.ModalWrapperMobile}` : styles.ModalWrapper}>
            <div className={`${styles.Row} ${styles.RowTop}`}>
                <CircleButton className={styles.CloseButton} onClick={() => closeModal(EPISODE_MODAL_NAME)}>
                    <BsX className={styles.CloseButtonIcon} />
                </CircleButton>
                <Image className={styles.Thumbnail} src={data?.thumbnail} />
                <div className={styles.AdditionalInfo}>
                    <div></div>
                    <div className={styles.Duration}>{durationFormatter(data?.duration || 0)}</div>
                </div>
            </div>
            <div className={`${styles.Row} ${styles.RowBottom}`}>
                <div className={styles.Actions}>
                    <div>
                        <PlayButton url={`/${data?.groupInUrlName}/${data?.nameInUrl}`} />
                        <ShareButton pathname={`/${data?.groupInUrlName}/${data?.nameInUrl}`} />
                        <FavoriteButton nameInUrl={data?.nameInUrl} type={FavoriteButtonType.Episode} />
                    </div>
                </div>
                <div className={styles.Description}>
                    <Description loading={false} error={null} {...data} duration={null} />
                </div>
            </div>
        </div>
    );
};

export { EpisodeCard, EpisodeModalCard };
