import { FC } from 'react';
import styles from 'styles/Nav.module.css';

interface NavProps {
  children?: React.ReactNode;
}

const Nav: FC<NavProps> = ({ children }) => {
  return <div className={styles.Wrapper}>{children}</div>;
};

export { Nav };
