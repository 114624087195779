import { FC } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link, To } from 'react-router-dom';
import styles from 'styles/TitledSection.module.css';
import { ContentWrapper } from './ContentWrapper';

interface TitledSectionProps {
  title: string;
  titleColor?: string;
  titleSize?: string;
  children?: React.ReactNode;
  linkTo?: To;
  linkText?: string;
  gap?: string;
}

const TitledSection: FC<TitledSectionProps> = ({
  title,
  titleColor,
  titleSize,
  children,
  linkTo,
  linkText,
  gap = '.5em',
}) => {
  return (
    <div
      className={styles.Wrapper}
      style={{ gap: gap }}
    >
      <ContentWrapper>
        <div className={styles.TitleWithLink}>
          <div
            style={{
              color: titleColor,
              fontSize: titleSize,
            }}
            className={styles.Title}
          >
            {title}
          </div>
          {linkTo ? (
            <div className={styles.Link}>
              <Link to={linkTo}>
                {linkText || 'Show'} <BsArrowRight />
              </Link>
            </div>
          ) : null}
        </div>
      </ContentWrapper>
      {children}
    </div>
  );
};

export { TitledSection };
