import { EpisodeCard } from "components/EpisodeCard";
import LoadingAnimation from "components/LoadingAnimation";
import { withErrorMessage } from "hocs/withErrorMessage";
import { withLoader } from "hocs/withLoader";
import Multimedia from "interfaces/data/Multimedia.interface";
import { FC } from "react";
import { Slider } from "./Slider";
import sliderLoaderStyles from "styles/Loaders.module.css";
import { useSliderSettings } from "hooks/useSliderSettings";

interface EpisodeSliderProps {
    firstElementIndex?: number;
    activeMaterialNameInUrl?: string;
    numberOfElementsToSlide?: number;
    displayPaginationIndicator?: boolean;

    elements?: Multimedia[] | null;
}

export const EPISODE_SLIDER_NAME = "episode";

const EpisodeSliderLoader: FC = () => {
    const settings = useSliderSettings(EPISODE_SLIDER_NAME);

    return settings !== undefined ? (
        <div
            className={sliderLoaderStyles.LoaderWrapper}
            style={{
                width: `calc(100% - ${settings.padding} * 2)`,
                gap: settings.gap,
                marginLeft: settings.padding,
            }}
        >
            {new Array(Math.ceil(settings.numberOfElementsToDisplay)).fill(0).map((item, index) => {
                return (
                    <div
                        className={sliderLoaderStyles.LoaderElement}
                        style={{ width: settings.elemWidth, height: settings.elemHeight }}
                        key={index}
                    >
                        <LoadingAnimation
                            animationDuration="5s"
                            animationHeight="500%"
                            animationWidth="50%"
                            animationYTranslation="-120%"
                        />
                    </div>
                );
            })}
        </div>
    ) : null;
};

const EpisodeSliderBase: FC<EpisodeSliderProps> = ({
    elements,
    activeMaterialNameInUrl,
    firstElementIndex,
    numberOfElementsToSlide,
    displayPaginationIndicator,
}) => {
    const settings = useSliderSettings(EPISODE_SLIDER_NAME);

    return settings !== undefined ? (
        <Slider
            {...settings}
            elements={elements || []}
            startingElementIndex={firstElementIndex || 0}
            renderItem={(item, itemPosition, { elemWidth, elemHeight }, mobile) => (
                <EpisodeCard
                    data={item}
                    itemPosition={itemPosition}
                    width={elemWidth}
                    height={elemHeight}
                    mobile={mobile}
                    active={item.nameInUrl === activeMaterialNameInUrl}
                />
            )}
            keyExtractor={(item) => item.nameInUrl}
            numberOfElementsToSlide={numberOfElementsToSlide || settings.numberOfElementsToSlide}
            displayPaginationIndicator={displayPaginationIndicator}
        />
    ) : null;
};

const EpisodeSlider = withErrorMessage(
    withLoader(EpisodeSliderBase, EpisodeSliderLoader, (props) => props.loading),
    true,
    (props) => props.error,
    (props) => props.elements,
);

export { EpisodeSlider };
