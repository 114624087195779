import { getFavoriteGroups } from "api/group";
import { getFavoriteMultimediaMaterials } from "api/multimediaMaterials";
import Group from "interfaces/data/Group.interface";
import Multimedia from "interfaces/data/Multimedia.interface";
import { useCallback, useEffect } from "react";
import { ToastType } from "redux/slices/toastSlice";
import { setFavoriteEpisodesCodes, setFavoriteMaterialsCodes } from "redux/slices/userSlice";
import { guid } from "utils/guid";
import { useAppDispatch } from "./useAppDispatch";
import { useFetch } from "./useFetch";
import { usePreload } from "./usePreload";
import useRequest from "./useRequest";
import { useToast } from "./useToast";

const useFavoritesInitialization = () => {
    const favoriteMaterials = useRequest<Group[]>(({ token }) => getFavoriteGroups({ token }));
    const favoriteEpisodes = useRequest<Multimedia[]>(({ token }) => getFavoriteMultimediaMaterials({ token }));
    const { setPreloadState } = usePreload();
    const dispatch = useAppDispatch();
    const { addToast } = useToast();

    useFetch(() => {
        fetch();
    });

    const fetch = useCallback(async () => {
        try {
            await favoriteMaterials.sendRequest();
            await favoriteEpisodes.sendRequest();
        } catch {
            addToast({
                uid: guid(),
                message: "An error occurred during data initialization - please refresh the page ",
                duration: null,
                type: ToastType.ERROR,
            });
        }
    }, []);

    useEffect(() => {
        if (favoriteMaterials.response.data !== null) {
            const codes = favoriteMaterials.response.data.map((g) => g.nameInUrl);
            dispatch(setFavoriteMaterialsCodes(codes));
        }
    }, [favoriteMaterials.response.loading]);

    useEffect(() => {
        if (favoriteEpisodes.response.data !== null) {
            const codes = favoriteEpisodes.response.data.map((g) => g.nameInUrl);
            dispatch(setFavoriteEpisodesCodes(codes));
            setPreloadState("favorites", true);
        }
    }, [favoriteEpisodes.response.loading]);
};

export { useFavoritesInitialization };
