import { del, endpointBuilder, get, post } from "./axios";

const getGroups = async (args: {
    token: string;
    params?: { filters?: string[]; phrase?: string; amount?: number };
}) => {
    return get(endpointBuilder(`/groups`, args.params), args.token);
};

const getGroup = async (args: { token: string; groupName: string; params?: { filters?: string[] } }) => {
    return get(endpointBuilder(`/groups/${args.groupName}`, args.params), args.token);
};

const getGroupArticle = async (args: { token: string; groupName: string }) => {
    return get(endpointBuilder(`/groups/${args.groupName}/article`), args.token);
};

const getNewsGroup = async (args: { token: string; params?: { amount?: number } }) => {
    return get(endpointBuilder(`/groups/news`, args.params), args.token);
};

const getFavoriteGroups = async (args: { token: string; params?: { amount?: number } }) => {
    return get(endpointBuilder(`/groups/favorites`, args.params), args.token);
};

const addFavoriteGroup = async (args: { token: string; nameInUrl: string }) => {
    return post(`/favorites/groups`, { nameInUrl: args.nameInUrl }, args.token);
};

const removeFavoriteGroup = async (args: { token: string; nameInUrl: string }) => {
    return del(`/favorites/groups/${args.nameInUrl}`, args.token);
};

export { getGroups, getGroup, getGroupArticle, getNewsGroup, getFavoriteGroups, addFavoriteGroup, removeFavoriteGroup };
