import { getFavoriteGroups } from "api/group";
import { getFavoriteMultimediaMaterials } from "api/multimediaMaterials";
import { EpisodeList } from "components/List/EpisodeList";
import { PageContent } from "components/PageContent";
import { MaterialSlider } from "components/Sliders/MaterialSlider";
import { TitledSection } from "components/TitledSection";
import { withScrollUp } from "hocs/withScrollUp";
import { useAppSelector } from "hooks/useAppSelector";
import { useFetch } from "hooks/useFetch";
import { useLocation } from "hooks/useLocation";
import useRequest from "hooks/useRequest";
import Group from "interfaces/data/Group.interface";
import Multimedia from "interfaces/data/Multimedia.interface";
import { FC, useEffect } from "react";
import { selectFavoriteEpisodes, selectFavoriteMaterals } from "redux/slices/userSlice";

const Favorites: FC = () => {
    const { setLocation } = useLocation();
    const currentFavoriteEpisodes = useAppSelector(selectFavoriteEpisodes);
    const currentFavoriteMaterials = useAppSelector(selectFavoriteMaterals);
    useEffect(() => {
        setLocation(undefined);
    }, []);

    const favoriteEpisodes = useRequest<Multimedia[]>(({ token }) => getFavoriteMultimediaMaterials({ token }));
    const favoriteMaterials = useRequest<Group[]>(({ token }) => getFavoriteGroups({ token }));
    useFetch(() => {
        favoriteEpisodes.sendRequest();
        favoriteMaterials.sendRequest();
    });

    return (
        <PageContent marginTop="7em">
            <TitledSection title="Favorite materials">
                <MaterialSlider
                    elements={favoriteMaterials.response.data?.filter((e) =>
                        currentFavoriteMaterials.includes(e.nameInUrl),
                    )}
                    loading={favoriteMaterials.response.loading}
                    error={favoriteMaterials.response.error}
                />
            </TitledSection>
            <TitledSection title="Favorite episodes">
                <EpisodeList
                    elements={favoriteEpisodes.response.data?.filter((e) =>
                        currentFavoriteEpisodes.includes(e.nameInUrl),
                    )}
                    loading={favoriteEpisodes.response.loading}
                    error={favoriteEpisodes.response.error}
                />
            </TitledSection>
        </PageContent>
    );
};

export default withScrollUp(Favorites);
