import { useLayoutEffect } from 'react';

const useListener = <ElementType extends HTMLElement | Window>(
  element: ElementType,
  type: keyof HTMLElementEventMap,
  callback: (event: Event) => void,
  deps?: React.DependencyList | undefined
) => {
  useLayoutEffect(() => {
    element.addEventListener(type, callback);
    return () => {
      element.removeEventListener(type, callback);
    };
  }, deps);
};

export { useListener };
