import { useState } from 'react';
import { selectSliderCurrentSettingsForElement } from 'redux/slices/sliderSlice';
import { useAppSelector } from './useAppSelector';

const useSliderSettings = (key: string) => {
  const settings = useAppSelector(state => selectSliderCurrentSettingsForElement(key, state));
  return settings;
};

export { useSliderSettings };
