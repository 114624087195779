import { FC, useEffect, useMemo } from "react";
import { HeroFeaturedMaterial } from "components/HeroFeaturedMaterial";
import useRequest from "hooks/useRequest";
import Multimedia from "interfaces/data/Multimedia.interface";
import {
    getFeaturedMultimediaMaterials,
    getMultimediaMaterials,
    getNewMultimediaMaterials,
    getRandomMultimediaMaterial,
} from "api/multimediaMaterials";
import { EpisodeSlider } from "components/Sliders/EpisodeSlider";
import { TitledSection } from "components/TitledSection";
import { useFetch } from "hooks/useFetch";
import { useLocation } from "hooks/useLocation";
import { withScrollUp } from "hocs/withScrollUp";
import { selectParentCategoryByName } from "redux/slices/categorySlice";
import { useAppSelector } from "hooks/useAppSelector";
import { PageContent } from "components/PageContent";

const Home: FC = () => {
    const { setLocation } = useLocation();
    const zones = useAppSelector((state) => selectParentCategoryByName(state, "zones"));

    useEffect(() => {
        setLocation(undefined);
    }, []);

    const heroFeaturedMaterialData = useRequest<Multimedia>(({ token }) => getRandomMultimediaMaterial({ token }));

    const featuredMaterialsData = useRequest<Multimedia[]>(({ token }) => getFeaturedMultimediaMaterials({ token }));

    const newMaterialsData = useRequest<Multimedia[]>(({ token }) =>
        getNewMultimediaMaterials({ params: { amount: 16 }, token }),
    );

    useFetch(() => {
        heroFeaturedMaterialData.sendRequest();
        newMaterialsData.sendRequest();
        featuredMaterialsData.sendRequest();
    });

    const zoneSliders = useMemo(
        () =>
            zones?.children.map((zone, index) => (
                <ZoneEpisodeSlider
                    zoneDisplayName={zone.displayName || zone.name}
                    zoneId={zone.id}
                    zoneName={zone.name}
                    key={index}
                />
            )),
        [zones],
    );

    return (
        <>
            <HeroFeaturedMaterial
                loading={heroFeaturedMaterialData.response.loading}
                material={heroFeaturedMaterialData.response.data}
                error={heroFeaturedMaterialData.response.error}
            />
            <PageContent>
                <TitledSection title="New episodes" linkText="Show new materials" linkTo={"/new"}>
                    <EpisodeSlider
                        elements={newMaterialsData.response.data || null}
                        error={newMaterialsData.response.error}
                        loading={newMaterialsData.response.loading}
                    />
                </TitledSection>
                {zoneSliders}
            </PageContent>
        </>
    );
};

const ZoneEpisodeSlider: FC<{ zoneId: number; zoneDisplayName: string; zoneName: string }> = ({
    zoneId,
    zoneDisplayName,
    zoneName,
}) => {
    const zoneEpisodes = useRequest<Multimedia[]>(({ token }) =>
        getMultimediaMaterials({ params: { filters: [zoneId.toString()] }, token }),
    );
    useFetch(() => {
        zoneEpisodes.sendRequest();
    });
    return (
        <TitledSection title={zoneDisplayName} linkText="Show all" linkTo={`/category/${zoneName}`}>
            <EpisodeSlider
                elements={zoneEpisodes.response.data || null}
                error={zoneEpisodes.response.error}
                loading={zoneEpisodes.response.loading}
            />
        </TitledSection>
    );
};

export default withScrollUp(Home);
