import { Button } from "components/Button";
import { SEARCH_MODAL_NAME } from "components/Modals/SearchModal";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useModalManager } from "hooks/useModal";
import { FC, FormEvent, useEffect, useRef, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { selectAllCategories } from "redux/slices/categorySlice";
import styles from "styles/SearchForm.module.css";

const SearchForm: FC = () => {
    const filtersToDisplay = ["zones", "languages", "subjects", "types"];
    const categories = useAppSelector(selectAllCategories);
    const navigate = useNavigate();
    const { closeModal } = useModalManager();

    const phraseRef = useRef<HTMLInputElement | null>(null);
    const [filters, setFilters] = useState<{ [key: string]: { id: number; value: boolean } }>({});

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (phraseRef.current) {
            const filtersArray = Object.values(filters)
                .filter((item) => item.value)
                .map((item, index) => {
                    if (item.value) return "filters=" + item.id.toString();
                });
            navigate(
                `/search?phrase=${phraseRef.current.value}&${
                    filtersArray.length > 0
                        ? filtersArray.reduce((prev, curr) => {
                              if (curr !== undefined) return prev + "&" + curr;
                          })
                        : ""
                }`,
            );
            closeModal(SEARCH_MODAL_NAME);
        }
    };

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.checked;
        const id = filters[name].id;
        setFilters((state) => ({ ...state, [name]: { id: id, value } }));
    };

    useEffect(() => {
        if (categories) {
            filtersToDisplay.forEach((filter) => {
                const category = categories.find((c) => c.name === filter);
                const categoryChildren = category?.children;
                if (categoryChildren !== undefined) {
                    const categoryFiltersState: { [key: string]: { id: number; value: boolean } } = {};
                    categoryChildren.forEach((item) => {
                        categoryFiltersState[item.name] = { id: item.id, value: false };
                    });
                    setFilters((state) => ({ ...state, ...categoryFiltersState }));
                }
            });
        }
    }, [JSON.stringify(categories)]);

    return (
        <div className={styles.FormWrapper}>
            <form className={styles.Form} onSubmit={handleSubmit}>
                <div className={styles.PhraseInputWrapper}>
                    <input
                        type="text"
                        ref={phraseRef}
                        name="phrase"
                        className={styles.TextInput}
                        placeholder={"Search the mediplayer..."}
                    />
                    <Button className={styles.SubmitButton} type="submit">
                        <BsSearch style={{ fontSize: "inherit" }} />
                    </Button>
                </div>
                <div className={styles.FiltersWrapper}>
                    <p className={styles.Paragraph}>You can narrow your search using the filters below.</p>
                    <div className={styles.Filters}>
                        {filtersToDisplay.map((item, index) => {
                            const category = categories?.find((c) => c.name === item);
                            if (!category) return null;
                            return (
                                <div key={category.id}>
                                    <h1>{category.displayName}</h1>
                                    <div className={styles.FiltersGroup}>
                                        {category.children.map((item, index) => {
                                            return filters[item.name] ? (
                                                <div key={index} className={styles.FiltersGroupOption}>
                                                    <input
                                                        type={"checkbox"}
                                                        key={item.id}
                                                        name={item.name}
                                                        checked={filters[item.name].value}
                                                        onChange={handleCheck}
                                                        className={styles.Checkbox}
                                                    />
                                                    {item.displayName}
                                                </div>
                                            ) : null;
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </form>
        </div>
    );
};

export { SearchForm };
