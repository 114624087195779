import { createSlice } from "@reduxjs/toolkit";
import { FavoriteButtonType } from "components/Button";
import { RootState } from "../store";

interface UserSlice {
    loggedIn: boolean;
    token?: string;
    specialization?: number;
    favorite: {
        materials: string[];
        episodes: string[];
    };
}

const INITIAL_STATE: UserSlice = {
    loggedIn: false,
    specialization: undefined,
    token: undefined,
    favorite: {
        materials: [],
        episodes: [],
    },
};

const { actions, reducer } = createSlice({
    name: "user",
    initialState: INITIAL_STATE,
    reducers: {
        setActiveSpecialization(state, action: { payload: number | undefined }) {
            state.specialization = action.payload;
        },
        setUserLoggedInAndTokenStates(state, action: { payload: { loggedIn: boolean; token: string | undefined } }) {
            const { loggedIn, token } = action.payload;
            state.loggedIn = loggedIn;
            state.token = token;
        },
        setFavoriteMaterialsCodes(state, action: { payload: string[] }) {
            state.favorite.materials = action.payload;
        },
        addFavoriteMaterialCode(state, action: { payload: string }) {
            console.log("add");
            const newCode = action.payload;
            const favoriteMaterials = state.favorite.materials;
            if (favoriteMaterials.find((e) => e === newCode) !== undefined) return;
            favoriteMaterials.push(newCode);
        },
        removeFavoriteMaterialCode(state, action: { payload: string }) {
            console.log("remove");
            const codeToRemove = action.payload;
            const favoriteMaterials = state.favorite.materials;
            const index = favoriteMaterials.findIndex((e) => e === codeToRemove);
            if (index === -1) return;
            favoriteMaterials.splice(index, 1);
        },
        setFavoriteEpisodesCodes(state, action: { payload: string[] }) {
            state.favorite.episodes = action.payload;
        },
        addFavoriteEpisodeCode(state, action: { payload: string }) {
            const newCode = action.payload;
            const favoriteEpisodes = state.favorite.episodes;
            if (favoriteEpisodes.find((e) => e === newCode) !== undefined) return;
            favoriteEpisodes.push(newCode);
        },
        removeFavoriteEpisodeCode(state, action: { payload: string }) {
            const codeToRemove = action.payload;
            const favoriteEpisodes = state.favorite.episodes;
            const index = favoriteEpisodes.findIndex((e) => e === codeToRemove);
            if (index === -1) return;
            favoriteEpisodes.splice(index, 1);
        },
    },
    extraReducers: {},
});

const selectFavoriteMaterals = (state: RootState) => state.user.favorite.materials;
const selectFavoriteEpisodes = (state: RootState) => state.user.favorite.episodes;
const selectActiveSpecialization = (state: RootState) => state.user.specialization;
const selectLoggedIn = (state: RootState) => state.user.loggedIn;
const selectToken = (state: RootState) => state.user.token;
const isFavorite = (state: RootState, type: FavoriteButtonType, nameInUrl: string) => {
    if (type === FavoriteButtonType.Episode) {
        return state.user.favorite.episodes.find((e) => e === nameInUrl) !== undefined;
    } else {
        return state.user.favorite.materials.find((e) => e === nameInUrl) !== undefined;
    }
};

export const {
    setActiveSpecialization,
    setUserLoggedInAndTokenStates,
    setFavoriteMaterialsCodes,
    setFavoriteEpisodesCodes,
    addFavoriteEpisodeCode,
    addFavoriteMaterialCode,
    removeFavoriteEpisodeCode,
    removeFavoriteMaterialCode,
} = actions;
export {
    selectActiveSpecialization,
    selectLoggedIn,
    selectToken,
    isFavorite,
    selectFavoriteMaterals,
    selectFavoriteEpisodes,
};
export default reducer;
