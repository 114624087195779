import { useModalManager } from 'hooks/useModal';
import { FC } from 'react';
import { BsX } from 'react-icons/bs';
import { FacebookIcon, FacebookShareButton } from 'react-share';
import styles from 'styles/Share.module.css';
import { Button } from './Button';
import { SHARE_MODAL_NAME } from './Modals/ShareModal';

interface ShareProps {
  url: string;
}

const Share: FC<ShareProps> = ({ url }) => {
  const { closeModal } = useModalManager();
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Header}>
        <div className={styles.Title}>Share</div>
        <Button
          className={styles.CloseButton}
          onClick={() => closeModal(SHARE_MODAL_NAME)}
        >
          <BsX style={{ fontSize: '1em' }} />
        </Button>
      </div>
      <div className={styles.Content}>
        <div className={styles.Url}>{url}</div>
        <div className={styles.ShareOptions}>
          <FacebookShareButton url={url}>
            <FacebookIcon
              round={true}
              className={styles.ShareOption}
            />
          </FacebookShareButton>
        </div>
      </div>
    </div>
  );
};

export { Share };
