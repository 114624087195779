import { withErrorMessage } from 'hocs/withErrorMessage';
import { withLoader } from 'hocs/withLoader';
import styles from 'styles/MaterialDesctiption.module.css';
import { FC } from 'react';
import LoadingAnimation from './LoadingAnimation';
import { ErrorProps } from './ErrorMessage';
import Group from 'interfaces/data/Group.interface';
import { Description } from './Description';

interface MaterialDescriptionProps {
  data: Group | null;
  loading: boolean | null;
  error: ErrorProps | null;
}

const MaterialDescription: FC<MaterialDescriptionProps> = ({ data, loading, error }) => {
  return (
    <div className={styles.Wrapper}>
      <Description
        loading={loading}
        error={error}
        {...data}
        title={null}
      />
    </div>
  );
};

export { MaterialDescription };
