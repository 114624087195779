import { getMultimediaMaterials } from "api/multimediaMaterials";
import { EpisodeList } from "components/List/EpisodeList";
import { PageContent } from "components/PageContent";
import { TitledSection } from "components/TitledSection";
import { useFetch } from "hooks/useFetch";
import { useLocation } from "hooks/useLocation";
import useRequest from "hooks/useRequest";
import Multimedia from "interfaces/data/Multimedia.interface";
import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const Search: FC = () => {
    const [searchParams] = useSearchParams();
    const { setLocation } = useLocation();
    const episodesData = useRequest<Multimedia[]>(({ token }) =>
        getMultimediaMaterials({
            params: {
                phrase: searchParams.get("phrase") || "",
                amount: 40,
                filters: searchParams.getAll("filters").length > 0 ? searchParams.getAll("filters") : undefined,
            },
            token,
        }),
    );
    useEffect(() => {
        setLocation(undefined);
    }, []);
    useFetch(() => {
        episodesData.sendRequest();
    }, [searchParams]);
    return (
        <PageContent marginTop="7em">
            <TitledSection title="Search results">
                <EpisodeList
                    loading={episodesData.response.loading}
                    error={episodesData.response.error}
                    elements={episodesData.response.data}
                />
            </TitledSection>
        </PageContent>
    );
};

export default Search;
