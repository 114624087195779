import { FC } from 'react';
import styles from 'styles/List.module.css';

export interface ListProps {
  gridTemplateColumns?: string;
  padding: string;
  gap: string;
  children: React.ReactNode;
}

const List: FC<ListProps> = ({ gridTemplateColumns, padding, gap, children }) => {
  return (
    <div className={styles.Wrapper}>
      <div
        className={styles.Content}
        style={{
          padding: padding,
          gap: gap,
          gridTemplateColumns: gridTemplateColumns || '1fr',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export { List };
