import { Page } from 'components/Page';
import { useLocation } from 'hooks/useLocation';
import { FC, useEffect } from 'react';

const NotFound: FC = () => {
  const { setLocation } = useLocation();
  useEffect(() => {
    setLocation(undefined);
  }, []);
  return <>404</>;
};

export default NotFound;
