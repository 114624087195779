import { useCallback } from 'react';
import * as ModalSlice from 'redux/slices/modalSlice';
import { useAppDispatch } from './useAppDispatch';

const useModalManager = () => {
  const dispatch = useAppDispatch();

  const openModal = useCallback((key: string) => {
    dispatch(ModalSlice.setModalDisplayState({ key, display: true }));
  }, []);

  const closeModal = useCallback((key: string) => {
    dispatch(ModalSlice.setModalDisplayState({ key, display: false }));
    setTimeout(() => {
      updateModalData(key, {
        x: -1000,
        y: -1000,
      });
    }, 500);
  }, []);

  const updateModalData = useCallback((key: string, data: ModalSlice.ModalDataPayload<any>) => {
    dispatch(ModalSlice.updateModalData({ key, data }));
  }, []);

  const initModal = useCallback((key: string) => {
    dispatch(
      ModalSlice.updateModalData({
        key,
        data: {
          x: -1000,
          y: -1000,
          initWidth: 0,
          initHeight: 0,
          open: false,
          additionalData: undefined,
          mobile: false,
        },
      })
    );
  }, []);

  return { openModal, closeModal, updateModalData, initModal };
};

export { useModalManager };
