import { FC, useLayoutEffect, useState } from 'react';
import styles from 'styles/VideoPlayer.module.css';
import LoadingAnimation from './LoadingAnimation';
import loaderStyles from 'styles/Loaders.module.css';

interface VideoPlayerProps {
  onnetworkId: string;
}

const VideoPlayer: FC<VideoPlayerProps> = ({ onnetworkId }) => {
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    let scriptTag: HTMLScriptElement;
    let timeout: NodeJS.Timeout;

    if (onnetworkId) {
      scriptTag = document.createElement('script');

      scriptTag.src = `https://video.onnetwork.tv/embed.php?onnwid=11183&onnvid=${onnetworkId}&ap=0&cc=en&volume=10`;
      scriptTag.async = true;

      let element = document.getElementById('onnetwork-video');
      if (element) {
        element.appendChild(scriptTag);
        scriptTag.style.display = 'none';
        scriptTag.onload = () => {
          timeout = setTimeout(() => {
            setLoading(false);
            scriptTag.style.display = 'block';
          }, 2000);
        };
      }
      return () => {
        if (element) element.innerHTML = '';
        clearTimeout(timeout);
        scriptTag.onload = null;
        setLoading(true);
      };
    }
  }, [onnetworkId]);

  return (
    <div className={styles.Wrapper}>
      <div
        className={styles.Video}
        id={'onnetwork-video'}
      ></div>
      {loading ? (
        <div className={`${styles.Loader} ${loaderStyles.LoaderElement}`}>
          <LoadingAnimation animationDuration={'4s'} />
        </div>
      ) : null}
    </div>
  );
};

export { VideoPlayer };
