import { FC, useCallback, useState } from "react";
import styles from "styles/UserActions.module.css";
import userImage from "assets/images/user.png";
import { Image } from "components/Image";
import { DropDown } from "./DropDown";
import { CgSearch } from "react-icons/cg";
import { RiArrowDropUpFill, RiArrowDropDownFill } from "react-icons/ri";
import { BsArrowReturnLeft } from "react-icons/bs";
import { NavItem } from "./NavItem";
import { selectActiveSpecialization, setActiveSpecialization } from "redux/slices/userSlice";
import { useAppSelector } from "hooks/useAppSelector";
import { selectParentCategoryByName } from "redux/slices/categorySlice";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { useModalManager } from "hooks/useModal";
import { useAuth } from "hooks/useAuth";

interface UserActionsProps {}

const UserActions: FC<UserActionsProps> = () => {
    const { openModal } = useModalManager();

    return (
        <div className={styles.Wrapper}>
            <div className={styles.Search} onClick={() => openModal("search")}>
                <CgSearch className={styles.SearchIcon} />
            </div>
            <Specialization />
            <UserMenu />
        </div>
    );
};

const UserMenu: FC = () => {
    const [isDropDownOpen, setDropDownOpen] = useState(false);
    const handleClick = useCallback(() => setDropDownOpen(!isDropDownOpen), [isDropDownOpen]);
    const { signOut } = useAuth();

    return (
        <div className={styles.User} onClick={handleClick}>
            <Image src={userImage} alt="user" className={styles.UserImage} fetchFromLocalServer />
            <DropDown isOpen={isDropDownOpen} wrapperClassName={styles.UserMenu}>
                <div className={styles.MobileOnly}>
                    <NavItem
                        onClick={() => setDropDownOpen(false)}
                        displayName={<BsArrowReturnLeft style={{ fontSize: "1.4em" }} />}
                    />
                </div>
                <NavItem
                    onClick={() => {
                        setDropDownOpen(false);
                        signOut();
                    }}
                    displayName={"Sign out"}
                />
            </DropDown>
        </div>
    );
};

const Specialization: FC = () => {
    const [isDropDownOpen, setDropDownOpen] = useState(false);

    const handleClick = useCallback(() => setDropDownOpen(!isDropDownOpen), [isDropDownOpen]);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const activeSpecialization = useAppSelector(selectActiveSpecialization);
    const specializations = useAppSelector((state) => selectParentCategoryByName(state, "specializations"));

    return (
        <div onClick={handleClick} className={styles.Specialization}>
            <div className={styles.CurrentSpecialization}>
                {activeSpecialization === undefined
                    ? "All"
                    : specializations?.children.find((e) => e.id === activeSpecialization)?.displayName}
                {isDropDownOpen ? (
                    <RiArrowDropUpFill className={styles.DropDownIcon} />
                ) : (
                    <RiArrowDropDownFill className={styles.DropDownIcon} />
                )}
            </div>
            <DropDown isOpen={isDropDownOpen} wrapperClassName={styles.Specializations}>
                <div className={styles.MobileOnly}>
                    <NavItem
                        onClick={() => setDropDownOpen(false)}
                        displayName={<BsArrowReturnLeft style={{ fontSize: "1.4em" }} />}
                    />
                </div>
                <NavItem
                    onClick={() => {
                        dispatch(setActiveSpecialization(undefined));
                        navigate("/");
                        setDropDownOpen(false);
                    }}
                    displayName={"All"}
                />
                {specializations?.children.map((item, index) => (
                    <NavItem
                        onClick={() => {
                            dispatch(setActiveSpecialization(item.id));
                            navigate("/");
                            setDropDownOpen(false);
                        }}
                        key={index}
                        displayName={item.displayName || item.name}
                    />
                ))}
            </DropDown>
        </div>
    );
};

export { UserActions };
