import { ContentWrapper } from 'components/ContentWrapper';
import { SearchForm } from 'components/Forms/SearchForm';
import { useListener } from 'hooks/useListener';
import { useModalManager } from 'hooks/useModal';
import { useModalData } from 'hooks/useModalData';
import { FC, useCallback, useEffect, useState } from 'react';
import { BsX } from 'react-icons/bs';
import { Modal } from './Modal';

interface SearcbModalProps {}
interface SearcbModalAdditionalData {
  url: string;
}

export const SEARCH_MODAL_NAME = 'search';
const SearchModal: FC<SearcbModalProps> = () => {
  const data = useModalData<SearcbModalAdditionalData>(SEARCH_MODAL_NAME);
  const { closeModal } = useModalManager();
  const [targetSizes, setTargetSizes] = useState({ width: 0, height: 0 });

  useEffect(() => {
    document.body.style.overflowY = data?.open ? 'hidden' : 'auto';
    updateTargetSizes();
  }, [data?.open]);

  const updateTargetSizes = useCallback(() => {
    setTargetSizes({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  useListener(window, 'resize', updateTargetSizes, []);

  return data ? (
    <Modal
      itemProps={data}
      renderItem={() => (
        <div
          style={{
            backgroundColor: 'var(--color-2)',
            // display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: 'var(--font-size-2)',
          }}
        >
          <ContentWrapper style={{ height: '100%', overflowY: 'scroll' }}>
            <div
              style={{
                width: '2em',
                height: '2em',
                fontSize: '1.5em',
                position: 'absolute',
                top: '1em',
                right: '1em',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => closeModal(SEARCH_MODAL_NAME)}
            >
              <BsX
                style={{
                  color: 'var(--color-4)',
                }}
              />
            </div>
            <SearchForm />
          </ContentWrapper>
        </div>
      )}
      initWidth={500}
      initHeight={500}
      targetWidth={targetSizes.width}
      targetHeight={targetSizes.height}
      open={data.open}
      left={'50%'}
      top={'50%'}
      transform={'translate(-50%, -50%)'}
      position={'fixed'}
    />
  ) : null;
};

export { SearchModal };
