import { createSlice } from "@reduxjs/toolkit";
import { FavoriteButtonType } from "components/Button";
import { RootState } from "../store";

export type Location = {
    name: string;
    prevLocation:
        | {
              name: string;
              url: string;
          }
        | undefined;
    navLinks: { name: string; url: string }[];
    favoriteButton?: {
        type: FavoriteButtonType;
        url: string;
    };
    shareButton?: {
        pathname: string;
    };
};

interface LocationSlice {
    current?: {
        name: string;
        url?: string;
        prevLocation:
            | {
                  name: string;
                  url: string;
              }
            | undefined;
        navLinks: { name: string; url: string }[];
        favoriteButton?: {
            type: FavoriteButtonType;
            url: string;
        };
        shareButton?: {
            pathname: string;
        };
    };
}

const INITIAL_STATE: LocationSlice = {
    current: undefined,
};

const { actions, reducer } = createSlice({
    name: "location",
    initialState: INITIAL_STATE,
    reducers: {
        setLocation(state, action: { payload: Location | undefined }) {
            state.current = action.payload;
        },
    },
});

const selectLocation = (state: RootState) => state.location.current;

export const { setLocation } = actions;
export { selectLocation };
export default reducer;
