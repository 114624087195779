import { FC } from 'react';
import { ErrorProps } from './ErrorMessage';
import styles from 'styles/MaterialArticle.module.css';
import { withErrorMessage } from 'hocs/withErrorMessage';
import { withLoader } from 'hocs/withLoader';
import { ContentWrapper } from './ContentWrapper';
import Article from 'interfaces/data/Article.interface';
import 'img-gallery.css';

interface MaterialArticleProps {
  data: Article | null;
  loading: boolean | null;
}

const Base: FC<MaterialArticleProps> = ({ data }) => {
  return data !== null ? (
    <div className={styles.Wrapper}>
      <ContentWrapper>
        <div className={styles.Article}>
          <div className={styles.Title}>{data?.title}</div>
          <div
            className={styles.Content}
            dangerouslySetInnerHTML={{ __html: data?.content || '' }}
          ></div>
        </div>
      </ContentWrapper>
    </div>
  ) : null;
};

const Loader: FC = () => {
  return <div className={styles.Wrapper}></div>;
};

export const MaterialArticle = withLoader(Base, Loader, item => item.loading);
