import { useAppSelector } from 'hooks/useAppSelector';
import { useParams } from 'react-router-dom';
import { selectParentCategoryByName } from 'redux/slices/categorySlice';
import { flattenCategories } from 'utils/flattenCategories';

function withPageZoneData<T>(Component: React.ComponentType<T>) {
  return (props: T) => {
    const { category } = useParams();
    const zones = useAppSelector(state => selectParentCategoryByName(state, 'zones'));
    if (zones) {
      const categories = flattenCategories(zones.children);
      const pageZoneData = categories.find(e => e.name === category);

      return (
        <Component
          {...props}
          pageZoneData={pageZoneData}
        />
      );
    }
    return null;
  };
}

export default withPageZoneData;
