import { ContentWrapper } from "components/ContentWrapper";
import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import styles from "styles/Slider.module.css";

export enum SliderItemPosition {
    LEFT,
    INNER,
    RIGHT,
}

interface GenericArrowProps {
    width: number;
    onClick: any;
    children: React.ReactNode;
    display: boolean;
    className: string;
}

interface ArrowProps {
    width: number;
    onClick: any;
    display: boolean;
}

interface PaginationIndicatorProps {
    numberOfPages: number;
    currentPage: number;
    display: boolean;
}

export interface SliderProps<ELEM_TYPE> {
    elemWidth: number;
    elemHeight: number;
    padding: number;
    gap: number;
    mobile: boolean;
    numberOfElementsToDisplay: number;
    numberOfElementsToSlide: number;
    elements: ELEM_TYPE[];
    startingElementIndex: number;
    displayPaginationIndicator?: boolean;
    renderItem: (
        item: ELEM_TYPE,
        itemPosition: SliderItemPosition,
        elemSettings: { elemWidth: number; elemHeight: number },
        mobile: boolean,
    ) => React.ReactNode;
    keyExtractor: (item: ELEM_TYPE) => string;
}

//SLIDER MAIN COMPONENT

const Slider = <T extends unknown>({
    elemWidth,
    elemHeight,
    padding,
    gap,
    mobile,
    numberOfElementsToDisplay,
    numberOfElementsToSlide,
    elements,
    startingElementIndex,
    renderItem,
    keyExtractor,
    displayPaginationIndicator = true,
}: SliderProps<T>) => {
    const {
        right,
        left,
        firstElementIndex,
        wrapperRef,
        displayLeftArrow,
        displayRightArrow,
        currentPage,
        numberOfPages,
    } = useSlider(
        startingElementIndex,
        elements.length,
        numberOfElementsToSlide,
        numberOfElementsToDisplay,
        mobile,
        elemWidth,
        gap,
    );

    const wrapperWidth = numberOfElementsToDisplay * elemWidth + 2 * padding + (numberOfElementsToDisplay - 1) * gap;

    const wrapperClassNames = mobile ? `${styles.Wrapper} ${styles.Mobile}` : styles.Wrapper;

    const sliderStyles = {
        width: `100%`,
        marginLeft: `${padding}px`,
    };

    const sliderContentStyles = {
        marginLeft: mobile ? 0 : `calc(${firstElementIndex} * (${elemWidth + "px"} + ${gap}px) * -1)`,
        gap: gap,
        paddingRight: elements.length * elemWidth + 1 * padding + (elements.length - 1) * gap,
    };

    const sliderContent = elements.map((item, index) => (
        <div key={keyExtractor(item)} className={styles.SliderItem}>
            {renderItem(
                item,
                index === firstElementIndex
                    ? SliderItemPosition.LEFT
                    : index === firstElementIndex + numberOfElementsToDisplay - 1
                    ? SliderItemPosition.RIGHT
                    : SliderItemPosition.INNER,
                {
                    elemHeight,
                    elemWidth,
                },
                mobile,
            )}
        </div>
    ));

    return (
        <div
            className={wrapperClassNames}
            style={{
                width: wrapperWidth,
            }}
            ref={wrapperRef}
        >
            <PaginationIndicator
                display={numberOfPages > 1 && displayPaginationIndicator && !mobile}
                currentPage={currentPage}
                numberOfPages={numberOfPages}
            />
            <div className={styles.Slider} style={sliderStyles}>
                <div className={styles.SliderContent} style={sliderContentStyles}>
                    {sliderContent}
                </div>
            </div>
            <ArrowLeft width={padding} onClick={left} display={displayLeftArrow} />
            <ArrowRight width={padding} onClick={right} display={displayRightArrow} />
        </div>
    );
};

//SLIDER MAIN COMPONENT END

//AUXILIARY COMPONENTS

const ArrowRight: FC<ArrowProps> = (props) => (
    <GenericArrow {...props} className={styles.ArrowRight}>
        <MdOutlineArrowForwardIos />
    </GenericArrow>
);

const ArrowLeft: FC<ArrowProps> = (props) => (
    <GenericArrow {...props} className={styles.ArrowLeft}>
        <MdOutlineArrowBackIos />
    </GenericArrow>
);

const GenericArrow: FC<GenericArrowProps> = ({ width, onClick, children, display, className }) =>
    display ? (
        <div style={{ width: width }} onClick={onClick} className={`${styles.Arrow} ${className}`}>
            {children}
        </div>
    ) : null;

const PaginationIndicator: FC<PaginationIndicatorProps> = ({ numberOfPages, currentPage, display }) => {
    return (
        <ContentWrapper>
            <div className={styles.PaginationIndicatorWrapper}>
                {display ? (
                    <div className={styles.PaginationIndicator}>
                        {new Array(numberOfPages).fill(0).map((item, index) => {
                            const active = index + 1 === currentPage;
                            return (
                                <div
                                    key={index}
                                    className={
                                        active
                                            ? `${styles.PaginationIndicatorPage} ${styles.PaginationIndicatorPageActive}`
                                            : styles.PaginationIndicatorPage
                                    }
                                    style={{
                                        color: active ? "red" : "initial",
                                    }}
                                ></div>
                            );
                        })}
                    </div>
                ) : null}
            </div>
        </ContentWrapper>
    );
};

//AUXILIARY COMPONENTS END

//SLIDER COMPONENT LOGIC

const useSlider = (
    startingElementIndex: number,
    numberOfElements: number,
    numberOfElementsToSlide: number,
    numberOfElementsToDisplay: number,
    mobileMode: boolean,
    elemWidth: number,
    gap: number,
) => {
    const [displayLeftArrow, setDisplayLeftArrow] = useState(true);
    const [displayRightArrow, setDisplayRightArrow] = useState(true);
    const [firstElementIndex, setFirstElementIndex] = useState(startingElementIndex);
    const [currentPage, setCurrentPage] = useState(1);
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const numberOfPages = useMemo(
        () => Math.ceil(numberOfElements / numberOfElementsToSlide),
        [numberOfElements, numberOfElementsToDisplay],
    );

    const pageRanges = useMemo(() => {
        const result: {
            [key: number]: { firstIndex: number; lastIndex: number };
        } = {};
        for (var i = 1; i <= numberOfPages; i++) {
            const firstIndex = numberOfElementsToSlide * i - numberOfElementsToSlide;
            const lastIndex = numberOfElementsToSlide * i - 1;
            result[i] = {
                firstIndex: firstIndex,
                lastIndex: lastIndex > numberOfElements - 1 ? numberOfElements - 1 : lastIndex,
            };
        }
        return result;
    }, [numberOfElementsToDisplay, numberOfElements, numberOfPages, numberOfElementsToSlide]);

    const updateFirstElementIndex = useCallback(
        (index: number) => {
            var newIndex = index;

            if (
                index + numberOfElementsToDisplay > pageRanges[numberOfPages].lastIndex &&
                numberOfElements > numberOfElementsToDisplay
            ) {
                newIndex = pageRanges[numberOfPages].lastIndex - numberOfElementsToDisplay + 1;
                setFirstElementIndex(newIndex);
            } else if (numberOfElements <= numberOfElementsToDisplay) setFirstElementIndex(0);
            else setFirstElementIndex(newIndex);

            return newIndex;
        },
        [pageRanges, numberOfElementsToDisplay, numberOfElements],
    );

    const left = useCallback(() => {
        if (!displayLeftArrow) return;
        const prevGroupRanges = pageRanges[currentPage - 1];
        updateFirstElementIndex(prevGroupRanges.firstIndex);
        setCurrentPage(currentPage - 1);
    }, [displayLeftArrow, currentPage, updateFirstElementIndex]);

    const right = useCallback(() => {
        if (!displayRightArrow) return;
        const nextPageRanges = pageRanges[currentPage + 1];
        updateFirstElementIndex(nextPageRanges.firstIndex);
        setCurrentPage(currentPage + 1);
    }, [displayRightArrow, currentPage, updateFirstElementIndex]);

    useEffect(() => {
        const newIndex = updateFirstElementIndex(startingElementIndex);
        if (newIndex !== 0 && numberOfElements > numberOfElementsToDisplay) {
            for (var i = 1; i <= numberOfPages; i++) {
                const page = pageRanges[i];
                if (page.firstIndex <= newIndex && page.lastIndex >= newIndex) {
                    setCurrentPage(i);
                    return;
                }
            }
        } else {
            setCurrentPage(1);
        }
    }, [startingElementIndex, numberOfElements, updateFirstElementIndex, pageRanges]);

    useEffect(() => {
        setDisplayLeftArrow(firstElementIndex !== 0);
        setDisplayRightArrow(
            firstElementIndex + numberOfElementsToDisplay !== numberOfElements &&
                numberOfElements > numberOfElementsToDisplay,
        );
    }, [numberOfElementsToDisplay, firstElementIndex, numberOfElements]);

    useLayoutEffect(() => {
        if (wrapperRef) {
            if (mobileMode) {
                wrapperRef.current?.scrollTo({
                    top: 0,
                    left: firstElementIndex * (elemWidth + gap) * 1,
                    behavior: "smooth",
                });
            } else {
                wrapperRef.current?.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
            }
        }
    }, [firstElementIndex, elemWidth, gap, wrapperRef, mobileMode]);

    return {
        right,
        left,
        firstElementIndex,
        wrapperRef,
        displayLeftArrow,
        displayRightArrow,
        numberOfPages,
        currentPage,
    };
};

//SLIDER COMPONENT LOGIC END

export { Slider };
