import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface PreloadSlice {
    preloadState: { [key: string]: boolean };
}

const INITIAL_STATE: PreloadSlice = {
    preloadState: {
        categories: false,
        favorites: false,
    },
};

const { actions, reducer } = createSlice({
    name: "preload",
    initialState: INITIAL_STATE,
    reducers: {
        setPreloadState(state, action: { payload: { key: string; value: boolean } }) {
            const { key, value } = action.payload;
            state.preloadState[key] = value;
        },
    },
    extraReducers: {},
});

const selectPreloadState = (state: RootState) => state.preload.preloadState;

export const { setPreloadState } = actions;
export { selectPreloadState };

export default reducer;
