import { FC } from "react";
import styles from "styles/PageContent.module.css";

interface Props {
    children?: React.ReactNode;
    marginTop?: string;
}

const PageContent: FC<Props> = ({ children, marginTop }) => {
    return (
        <div className={styles.Wrapper} style={{ marginTop }}>
            {children}
        </div>
    );
};

export { PageContent };
