import { getAllCategories } from "api/category";
import Category from "interfaces/data/Category.interface";
import { useCallback, useEffect } from "react";
import { setCategories } from "redux/slices/categorySlice";
import { ToastType } from "redux/slices/toastSlice";
import { guid } from "utils/guid";
import { useAppDispatch } from "./useAppDispatch";
import { useFetch } from "./useFetch";
import { usePreload } from "./usePreload";
import useRequest from "./useRequest";
import { useToast } from "./useToast";

const useCategoriesInitialization = () => {
    const categories = useRequest<Category[]>(({ token }) => getAllCategories({ token }));
    const { setPreloadState } = usePreload();
    const dispatch = useAppDispatch();
    const { addToast } = useToast();

    useFetch(() => {
        fetch();
    });

    const fetch = useCallback(async () => {
        try {
            await categories.sendRequest();
        } catch {
            addToast({
                uid: guid(),
                message: `An error occurred during data initialization - please refresh the page `,
                duration: null,
                type: ToastType.ERROR,
            });
        }
    }, []);

    useEffect(() => {
        const { loading, error, data } = categories.response;
        if (!loading && error == null && data) {
            dispatch(setCategories(data));
            setPreloadState("categories", true);
        }
    }, [categories.response.loading]);
};

export { useCategoriesInitialization };
