import { useCallback } from 'react';
import * as PreloadState from 'redux/slices/preloadSlice';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

const usePreload = () => {
  const dispatch = useAppDispatch();

  const setPreloadState = useCallback((key: string, value: boolean) => {
    dispatch(PreloadState.setPreloadState({ key, value }));
  }, []);

  return { setPreloadState };
};

const usePreloadState = () => {
  const state = useAppSelector(PreloadState.selectPreloadState);
  return state;
};

export { usePreload, usePreloadState };
