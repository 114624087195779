import styles from "styles/ToastManager.module.css";
import { FC } from "react";
import { useAppSelector } from "hooks/useAppSelector";
import { selectToasts } from "redux/slices/toastSlice";
import { Toast } from "./Toast";

const ToastManager: FC = () => {
    const toasts = useAppSelector(selectToasts);
    return (
        <div className={styles.Wrapper}>
            {toasts.map((item) => (
                <Toast key={item.uid} {...item} />
            ))}
        </div>
    );
};

export { ToastManager };
