import { MaterialCard } from "components/MaterialCard";
import { withErrorMessage } from "hocs/withErrorMessage";
import { withLoader } from "hocs/withLoader";
import Group from "interfaces/data/Group.interface";
import { FC } from "react";
import { Slider } from "./Slider";
import sliderLoaderStyles from "styles/Loaders.module.css";
import LoadingAnimation from "components/LoadingAnimation";
import { useSliderSettings } from "hooks/useSliderSettings";

interface MaterialSliderProps {
    elements?: Group[] | null;
}

export const MATERIAL_SLIDER_NAME = "material";

const MaterialSliderLoader: FC = () => {
    const settings = useSliderSettings(MATERIAL_SLIDER_NAME);

    return settings !== undefined ? (
        <div
            className={sliderLoaderStyles.LoaderWrapper}
            style={{
                width: `calc(100% - ${settings.padding} * 2)`,
                gap: settings.gap,
                marginLeft: settings.padding,
            }}
        >
            {new Array(Math.ceil(settings.numberOfElementsToDisplay)).fill(0).map((item, index) => {
                return (
                    <div
                        className={sliderLoaderStyles.LoaderElement}
                        style={{ width: settings.elemWidth, height: settings.elemHeight }}
                        key={index}
                    >
                        <LoadingAnimation
                            animationDuration="5s"
                            animationHeight="500%"
                            animationWidth="50%"
                            animationYTranslation="-120%"
                        />
                    </div>
                );
            })}
        </div>
    ) : null;
};

const MaterialSliderBase: FC<MaterialSliderProps> = ({ elements }) => {
    const settings = useSliderSettings(MATERIAL_SLIDER_NAME);

    return settings !== undefined ? (
        <Slider
            {...settings}
            elements={elements || []}
            startingElementIndex={0}
            renderItem={(item, itemPosition, { elemWidth, elemHeight }, mobile) => (
                <MaterialCard
                    data={item}
                    itemPosition={itemPosition}
                    width={elemWidth}
                    height={elemHeight}
                    mobile={mobile}
                />
            )}
            keyExtractor={(item) => item.nameInUrl}
        />
    ) : null;
};

const MaterialSlider = withErrorMessage(
    withLoader(MaterialSliderBase, MaterialSliderLoader, (item) => item.loading),
    true,
    (item) => item.error,
    (item) => item.elements,
);

export { MaterialSlider };
