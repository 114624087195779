import { useListener } from 'hooks/useListener';
import Multimedia from 'interfaces/data/Multimedia.interface';
import { FC, useCallback, useLayoutEffect, useRef, useState } from 'react';
import styles from 'styles/Video.module.css';
import { ErrorProps } from './ErrorMessage';
import { Description } from './Description';
import { VideoPlayerWithControlls } from './VideoPlayerWithControlls';
import { BsXSquare } from 'react-icons/bs';
import { Button } from './Button';
import { useNavigate } from 'react-router-dom';

interface VideoProps {
  video: Multimedia | null;
  prevVideoNameInUrl: string | undefined;
  nextVideoNameInUrl: string | undefined;
  loading: boolean | null;
  error: ErrorProps | null;
}

const Video: FC<VideoProps> = ({
  video,
  prevVideoNameInUrl,
  nextVideoNameInUrl,
  error,
  loading,
}) => {
  const playerRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [maxHeight, setMaxHeight] = useState(0);

  const handleResize = useCallback(() => {
    if (playerRef.current) {
      setMaxHeight(playerRef.current.clientHeight);
    }
  }, [playerRef]);

  useListener(window, 'resize', handleResize, [handleResize]);

  useLayoutEffect(() => {
    handleResize();
  }, []);

  const closeEpisode = useCallback(() => {
    if (video?.groupInUrlName !== undefined) navigate(`/${video?.groupInUrlName}`);
  }, [video?.groupInUrlName]);

  return (
    <div className={styles.Wrapper}>
      <div
        className={styles.Player}
        ref={playerRef}
      >
        <VideoPlayerWithControlls
          onnetworkId={video?.onnetworkID.toString() || ''}
          prevVideoNameInUrl={prevVideoNameInUrl}
          nextVideoNameInUrl={nextVideoNameInUrl}
          currentVideoNameInUrl={video?.nameInUrl || ''}
          playlistNameInUrl={video?.groupInUrlName || ''}
        />
      </div>
      <div
        className={styles.Description}
        style={{ height: maxHeight }}
      >
        <Button
          onClick={closeEpisode}
          className={styles.CloseButton}
        >
          <span style={{ fontSize: '.9em' }}>Close episode</span>{' '}
          <BsXSquare style={{ fontSize: '1em' }} />
        </Button>
        <Description
          loading={loading}
          error={error}
          {...video}
          duration={undefined}
        />
      </div>
    </div>
  );
};

export { Video };
