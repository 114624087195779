import { useEffect, useState } from "react";

const withLoader = <DATA extends {}, T extends DATA>(
    Component: React.ComponentType<T & { loading: boolean | null }>,
    Loader: React.ComponentType | null,
    loadingStateExtractor: (item: T & { loading: boolean | null }) => boolean | null,
) => {
    return (props: T & { loading: boolean | null }) => {
        const [displayLoader, setDisplayLoader] = useState(false);
        const loadingState = loadingStateExtractor(props);

        useEffect(() => {
            var timeout: NodeJS.Timeout;
            timeout = setTimeout(() => {
                setDisplayLoader(true);
            }, 500);
            return () => {
                setDisplayLoader(false);
                clearTimeout(timeout);
            };
        }, [loadingState]);

        return loadingState === null || loadingState === true ? (
            displayLoader && Loader !== null ? (
                <Loader />
            ) : null
        ) : (
            <Component {...props} />
        );
    };
};

export { withLoader };
