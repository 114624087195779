import { CSSProperties, FC, useCallback, useEffect, useId, useLayoutEffect, useState } from "react";
import styles from "styles/Header.module.css";
import { ContentWrapper } from "./ContentWrapper";
import { Image } from "./Image";
import { Nav } from "./Nav";
// import mediplayerLogo from "assets/images/mediplayerLogo.png";
import mediplayerLogo from "assets/images/medi_logo_jasne.svg";
// import mediplayerLogo from "assets/images/medi_logo_outline.svg";
import { UserActions } from "./UserActions";
import { NavItem } from "./NavItem";
import { useListener } from "hooks/useListener";
import { Link } from "react-router-dom";
import { useLocation } from "hooks/useLocation";
import { LocationBar } from "./LocationBar";
import { useAppSelector } from "hooks/useAppSelector";
import { selectParentCategoryByName } from "redux/slices/categorySlice";

interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
    const [showBg, setShowBg] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [barHeight, setBarHeight] = useState(0);
    const [stickyLocationBar, setStickyLocationBar] = useState(false);
    const barId = useId();
    const zones = useAppSelector((state) => selectParentCategoryByName(state, "zones"));

    const { current } = useLocation();

    const handleResize = useCallback(() => {
        setMobileMenuOpen(false);
        const barH = document.getElementById(barId)!!.clientHeight;
        setBarHeight(barH);
    }, []);

    useLayoutEffect(() => {
        handleResize();
    }, []);

    const handleScroll = useCallback(() => {
        if (window.scrollY > 5) setShowBg(true);
        else setShowBg(false);
        if (current !== undefined && window.scrollY > barHeight) {
            setStickyLocationBar(true);
            setMobileMenuOpen(false);
        } else {
            setStickyLocationBar(false);
        }
    }, [barHeight, JSON.stringify(current)]);

    useListener<Window>(window, "scroll", handleScroll, [barHeight, JSON.stringify(current)]);
    useListener<Window>(window, "resize", handleResize, []);

    const NavWithUserActions = (
        <>
            <div className={styles.Nav}>
                <Nav>
                    <NavItem onClick={() => setMobileMenuOpen(false)} displayName="Home" href={"/"} />
                    <NavItem onClick={() => setMobileMenuOpen(false)} displayName="New materials" href={"/new"} />
                    <NavItem displayName="Categories" href={"/category"} disableLocationChange={true}>
                        {zones?.children.map((item) => {
                            return (
                                <NavItem
                                    onClick={() => setMobileMenuOpen(false)}
                                    key={item.name}
                                    href={`/category/${item.name}`}
                                    displayName={item.displayName || item.name}
                                />
                            );
                        })}
                    </NavItem>
                    <NavItem onClick={() => setMobileMenuOpen(false)} displayName="Favorites" href={"/favorites"} />
                </Nav>
            </div>
            <div className={styles.UserActions}>
                <UserActions />
            </div>
        </>
    );

    return (
        <>
            <header
                className={styles.Bar}
                style={
                    {
                        background: showBg || mobileMenuOpen ? `var(--color-17)` : undefined,
                        boxShadow: showBg || mobileMenuOpen ? `0px 0px .5rem 0px #000000` : "initial",
                        "--navHeight": barHeight + "px",
                        position: current !== undefined ? "absolute" : "fixed",
                    } as CSSProperties
                }
                id={barId}
            >
                <ContentWrapper>
                    <div className={`${styles.Content} ${styles.DesktopContent}`}>
                        <div className={styles.LogoWrapper}>
                            <Link to={"/"}>
                                <Image
                                    className={styles.Logo}
                                    src={mediplayerLogo}
                                    alt="mediplayerLogo"
                                    fetchFromLocalServer
                                />
                            </Link>
                        </div>
                        {NavWithUserActions}
                        <div
                            className={styles.MobileContent}
                            style={{
                                display: mobileMenuOpen ? "flex" : "none",
                            }}
                        >
                            {NavWithUserActions}
                        </div>
                        <div className={styles.BurgerButtonWrapper}>
                            <div
                                className={mobileMenuOpen ? styles.BurgerButtonOpen : styles.BurgerButton}
                                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                            >
                                <div className={styles.BurgerButtonBar}></div>
                                <div className={styles.BurgerButtonBar}></div>
                                <div className={styles.BurgerButtonBar}></div>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </header>
            {current ? <LocationBar top={barHeight} sticky={stickyLocationBar} /> : null}
        </>
    );
};

export { Header };
