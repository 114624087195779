import { FC } from 'react';
import styles from 'styles/ContentWrapper.module.css';

interface ContentWrapperProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const ContentWrapper: FC<ContentWrapperProps> = ({ children, style }) => {
  return (
    <div
      style={style}
      className={styles.Wrapper}
    >
      {children}
    </div>
  );
};

export { ContentWrapper };
