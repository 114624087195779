import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export enum ToastType {
    INFO = "INFO",
    ERROR = "ERROR",
}

export interface IToast {
    uid: string;
    message: string;
    duration: number | null;
    type: ToastType;
}

interface IToastSlice {
    toasts: IToast[];
}

const INITIAL_STATE: IToastSlice = {
    toasts: [],
};

const { actions, reducer } = createSlice({
    name: "toast",
    initialState: INITIAL_STATE,
    reducers: {
        addToast(state, action: { payload: IToast }) {
            state.toasts.push(action.payload);
        },
        removeToast(state, action: { payload: string }) {
            const uid = action.payload;
            const toastUid = state.toasts.findIndex((t) => t.uid === uid);
            state.toasts.splice(toastUid, 1);
        },
    },
    extraReducers: {},
});

const selectToasts = (state: RootState) => state.toast.toasts;

export const { addToast, removeToast } = actions;
export { selectToasts };
export default reducer;
