import { EpisodeCard } from "components/EpisodeCard";
import { EPISODE_SLIDER_NAME } from "components/Sliders/EpisodeSlider";
import { SliderItemPosition } from "components/Sliders/Slider";
import { withErrorMessage } from "hocs/withErrorMessage";
import { withLoader } from "hocs/withLoader";
import { useSliderSettings } from "hooks/useSliderSettings";
import Multimedia from "interfaces/data/Multimedia.interface";
import { FC } from "react";
import { List, ListProps } from "./List";

interface EpisodeListProps {
    elements?: Multimedia[] | null;
}

const EpisodeListLoader: FC = () => {
    return <div></div>;
};

const EpisodeListBase: FC<EpisodeListProps> = ({ elements }) => {
    const settings = useSliderSettings(EPISODE_SLIDER_NAME);

    return settings !== undefined ? (
        <List
            padding={`0 ${settings.padding}px`}
            gap={`${settings.gap}px`}
            gridTemplateColumns={`repeat(${Math.floor(settings.numberOfElementsToDisplay)}, 1fr)`}
        >
            {elements?.map((item, index) => (
                <EpisodeCard
                    key={index}
                    width={settings.elemWidth}
                    height={settings.elemHeight}
                    data={item}
                    mobile={settings.mobile}
                    itemPosition={
                        index % settings.numberOfElementsToDisplay === 0
                            ? SliderItemPosition.LEFT
                            : index % settings.numberOfElementsToDisplay === settings.numberOfElementsToDisplay - 1
                            ? SliderItemPosition.RIGHT
                            : SliderItemPosition.INNER
                    }
                />
            ))}
        </List>
    ) : null;
};

const EpisodeList = withErrorMessage(
    withLoader(EpisodeListBase, EpisodeListLoader, (item) => item.loading),
    true,
    (item) => item.error,
    (item) => item.elements,
);

export { EpisodeList };
