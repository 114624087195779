import { getMultimediaMaterials } from "api/multimediaMaterials";
import { EpisodeList } from "components/List/EpisodeList";
import { PageContent } from "components/PageContent";
import { TitledSection } from "components/TitledSection";
import withPageZoneData from "hocs/withPageZoneData";
import { withScrollUp } from "hocs/withScrollUp";
import { useFetch } from "hooks/useFetch";
import { useLocation } from "hooks/useLocation";
import useRequest from "hooks/useRequest";
import Category from "interfaces/data/Category.interface";
import Multimedia from "interfaces/data/Multimedia.interface";
import { FC, useEffect } from "react";

const AllZoneEpisodes: FC<{ pageZoneData?: Category }> = ({ pageZoneData }) => {
    const { setLocation } = useLocation();
    const zoneMaterials = useRequest<Multimedia[]>(({ token }) =>
        getMultimediaMaterials({ params: { filters: [pageZoneData?.id?.toString() || "-1"] }, token }),
    );
    useEffect(() => {
        setLocation({
            name: `${pageZoneData?.displayName}`,
            prevLocation:
                pageZoneData?.parentName && pageZoneData.parentDisplayName && pageZoneData.parentName !== "zones"
                    ? {
                          name: `${pageZoneData?.parentDisplayName}`,
                          url: `/category/${pageZoneData?.parentName}/episodes`,
                      }
                    : undefined,
            navLinks:
                pageZoneData?.children.map((item) => ({
                    name: `${item.displayName}`,
                    url: `/category/${item.name}/episodes`,
                })) || [],
        });
    }, [pageZoneData?.id]);

    useFetch(() => {
        zoneMaterials.sendRequest();
    }, [pageZoneData?.id]);

    return (
        <PageContent>
            <TitledSection title="All episodes">
                <EpisodeList
                    loading={zoneMaterials.response.loading}
                    error={zoneMaterials.response.error}
                    elements={zoneMaterials.response.data}
                />
            </TitledSection>
        </PageContent>
    );
};

export default withScrollUp(withPageZoneData(AllZoneEpisodes));
