import { createSlice } from '@reduxjs/toolkit';
import Category from 'interfaces/data/Category.interface';
import { flattenCategories } from 'utils/flattenCategories';
import { RootState } from '../store';

interface CategorySlice {
  categories?: Category[];
}

const INITIAL_STATE: CategorySlice = {
  categories: undefined,
};

const { actions, reducer } = createSlice({
  name: 'category',
  initialState: INITIAL_STATE,
  reducers: {
    setCategories(state, action: { payload: Category[] }) {
      state.categories = action.payload;
    },
  },
  extraReducers: {},
});

const selectParentCategoryByName = (state: RootState, name: string) =>
  state.category.categories?.find(e => e.name === name);
const selectAllCategories = (state: RootState) => state.category.categories;
const selectFlattenedCategories = (state: RootState) => {
  const categories = state.category.categories;
  if (categories) return flattenCategories(categories);
};

export const { setCategories } = actions;
export { selectParentCategoryByName, selectAllCategories, selectFlattenedCategories };
export default reducer;
