import { FC } from 'react';
import styles from 'styles/ErrorMessage.module.css';
import { ContentWrapper } from './ContentWrapper';

export interface ErrorProps {
  code?: number | string;
  message?: string;
}

const ErrorMessage: FC<ErrorProps> = ({ code, message }) => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Code}>{code}</div>
      <div className={styles.Message}>{message}</div>
    </div>
  );
};

export default ErrorMessage;
