import { withErrorMessage } from "hocs/withErrorMessage";
import { withLoader } from "hocs/withLoader";
import styles from "styles/Desctiption.module.css";
import { FC } from "react";
import { durationFormatter } from "utils/duartionFormatter";
import LoadingAnimation from "./LoadingAnimation";
import { ErrorProps } from "./ErrorMessage";

interface DescriptionProps {
    loading: boolean | null;
    error: ErrorProps | null;

    titleSize?: string;

    title?: string | null;
    duration?: number | null;
    matID?: string | null;
    prelegents?: string | null;
    description?: string | null;
    categories?: { [key: string]: string[] } | null;
}

const Base: FC<DescriptionProps> = ({
    matID,
    duration,
    title,
    description,
    prelegents,
    categories,

    titleSize,
}) => {
    return (
        <div className={styles.Wrapper}>
            {matID ? <div className={styles.MatId}>{matID}</div> : null}
            {title ? (
                <div className={styles.Title} style={{ fontSize: titleSize }}>
                    {title}
                </div>
            ) : null}
            {duration ? <div className={styles.Duration}>{durationFormatter(duration)}</div> : null}
            {description ? <div className={styles.Description}>{description}</div> : null}
            {prelegents || categories ? (
                <div className={styles.Tags}>
                    {prelegents ? (
                        <div className={styles.Tag}>
                            <span>prelegents: </span>
                            {prelegents}
                        </div>
                    ) : null}
                    {categories?.categories ? (
                        <div className={styles.Tag}>
                            <span>categories: </span>
                            {categories?.categories?.join(", ")}
                        </div>
                    ) : null}
                    {categories?.subjects ? (
                        <div className={styles.Tag}>
                            <span>topics: </span>
                            {categories?.subjects?.join(", ")}
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

const Loader: FC = () => {
    return (
        <div className={styles.WrapperLoading}>
            <div className={styles.MatId}>
                <LoadingAnimation animationDuration="5s" />
            </div>
            <div className={styles.Title}>
                <LoadingAnimation animationDuration="5s" />
            </div>
            <div className={styles.Duration}>
                <LoadingAnimation animationDuration="5s" />
            </div>
            <div className={styles.Description}>
                <LoadingAnimation animationDuration="5s" />
            </div>
        </div>
    );
};

export const Description = withErrorMessage(
    withLoader(Base, Loader, (item) => item.loading),
    false,
    (item) => item.error,
);
